import * as React from 'react';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { gql, useMutation } from '@apollo/client';
import { Heading, majorScale, Pane, toaster } from 'evergreen-ui';

import {
  HTTP_REQ_STATUS,
  VIEWPORT_BREAKPOINTS,
} from '../../../../helpers/enums';
import Btn from '../../../../components/btn/btn';
import { getErrorMessage } from '../../../../helpers/functions';
import FormInput from '../../../../components/form-input/form-input';
import { formatIntegerInput } from '../../../../helpers/form-input-utils';

const VERIFY_RESET_PASSWORD_TOKEN = gql`
  mutation VerifyResetPasswordToken($token: String!) {
    verify_reset_password_token(token: $token) {
      email
    }
  }
`;

const VERIFY_RESET_TOKEN_SCHEMA = yup.object().shape({
  token: yup.string().required('Password Token is required'),
});

function VerifyToken({ setEmail, increaseStep }) {
  const [mutate] = useMutation(VERIFY_RESET_PASSWORD_TOKEN);
  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);

  async function handleVerifyResetToken(values) {
    const { token } = values;

    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data } = await mutate({
        variables: { token },
      });

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);

        setEmail(data.verify_reset_password_token.email);
        toaster.success(data.verify_reset_password_token.message, {
          id: 'token-success',
          description: 'Please set your new password.',
        });

        increaseStep();
      }
    } catch (error) {
      setStatus(HTTP_REQ_STATUS.error);
      getErrorMessage(error);
    }
  }

  const isSmallScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.sm}px)`,
  });

  return (
    <>
      <Heading
        marginBottom={majorScale(4)}
        color="#000"
        fontSize="1.5em"
        fontWeight={700}
        textAlign="center"
      >
        Reset Password
      </Heading>

      <Formik
        validationSchema={VERIFY_RESET_TOKEN_SCHEMA}
        initialValues={{ token: '' }}
        onSubmit={handleVerifyResetToken}
      >
        {({ handleSubmit }) => {
          return (
            <Pane is="form" onSubmit={handleSubmit} className="row">
              <Pane className="col-sm-6 col-12">
                <Field name="token">
                  {({ field, meta, form }) => {
                    return (
                      <FormInput
                        inputHeight={majorScale(5)}
                        label="Token"
                        name={field.name}
                        placeholder="Enter password reset token"
                        value={field.value}
                        isInvalid={meta.touched && !!meta.error}
                        validationMessage={meta.touched && meta.error}
                        onChange={(e) => {
                          form.setFieldValue(
                            field.name,
                            formatIntegerInput(e.target.value)
                          );
                        }}
                      />
                    );
                  }}
                </Field>
              </Pane>

              <Pane className="col-sm-6 col-12">
                <Btn
                  type="submit"
                  width="100%"
                  marginY={isSmallScreen ? majorScale(3) : 0}
                  height={majorScale(5)}
                  fontSize="1em"
                  isLoading={status === HTTP_REQ_STATUS.loading}
                >
                  Send Token
                </Btn>
              </Pane>
            </Pane>
          );
        }}
      </Formik>
    </>
  );
}

export default VerifyToken;
