import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Pane,
  Label,
  ListItem,
  ErrorIcon,
  Paragraph,
  UnorderedList,
  majorScale,
  minorScale,
} from 'evergreen-ui';

function FormRadioGroup({
  label,
  value,
  size = 20,
  options,
  alignOptions,
  orientation,
  isInvalid,
  validationMessage,
  onChange,
  disabled, // New disabled prop
}) {
  return (
    <>
      {label && (
        <Pane marginBottom={majorScale(1)} display="flex">
          <Label>{label}</Label>
        </Pane>
      )}

      <UnorderedList
        display="flex"
        flexDirection={orientation === 'vertical' ? 'column' : 'row'}
        rowGap={majorScale(2)}
        marginLeft={0}
      >
        {options.length > 0 &&
          options.map((option) => {
            const isOptionDisabled = disabled || option.disabled; // Check if option is disabled
            return (
              <ListItem
                key={option.value}
                display="flex"
                alignItems={alignOptions || 'center'}
                marginRight={majorScale(4)}
                cursor={!isOptionDisabled ? 'pointer' : 'not-allowed'} // Change cursor based on disabled state
                opacity={isOptionDisabled ? 0.5 : 1} // Adjust opacity based on disabled state
                onClick={
                  !isOptionDisabled ? () => onChange(option.value) : undefined
                } // Only trigger onClick if not disabled
              >
                <Text
                  marginRight={minorScale(3)}
                  display="inline-block"
                  width={size}
                  minWidth={size}
                  height={size}
                  borderRadius="50%"
                  border="3px solid var(--primary-default-color)"
                  background={
                    value === option.value
                      ? 'radial-gradient(var(--primary-default-color) 35%, transparent 55%)'
                      : 'transparent'
                  }
                ></Text>
                <Text>{option.label}</Text>
              </ListItem>
            );
          })}
      </UnorderedList>

      {isInvalid && (
        <Pane
          marginTop={majorScale(1)}
          display="flex"
          columnGap={majorScale(1)}
          color="#D14343"
        >
          <ErrorIcon />
          <Paragraph
            letterSpacing={0}
            color="#D14343"
            lineHeight="18px"
            fontSize="13px"
            fontWeight={400}
            marginBottom={0}
          >
            {validationMessage}
          </Paragraph>
        </Pane>
      )}
    </>
  );
}

FormRadioGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool, // Option disabled prop
    })
  ),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool, // New disabled prop
};

FormRadioGroup.defaultProps = {
  orientation: 'horizontal',
  disabled: false, // Default disabled state
};

export default FormRadioGroup;
