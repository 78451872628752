import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import { Pane, Paragraph, Heading, majorScale, minorScale } from 'evergreen-ui';
import Container from '../../components/container/container';
import { VIEWPORT_BREAKPOINTS } from '../../helpers/enums';
import MaintenanceBackground from '../../assets/images/maintenance-background.png';
import Maintenance from '../../assets/images/maintenance.png';
import { ReactComponent as MaintenanceLogo } from '../../assets/icons/maintenance-page-logo.svg';

const MaintenanceBanner = styled(Pane)`
  display: flex;
  align-items: center;
  background: ${(props) => ` url('${props.image}') no-repeat center`};
  background-size: 100% 100%;
  height: 475px;
  width: 100%;
  position: relative;

  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.lg}px) {
    height: 352px;
    width: 100%;
    align-items: flex-start;
  }
`;

const MaintenancePage = () => {
  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.lg}px)`,
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 16px)"
    >
      <Container maxWidth="1200px" paddingY={minorScale(28)}>
        <MaintenanceBanner image={MaintenanceBackground}>
          <Pane
            width={!isLaptop ? '100%' : '65%'}
            textAlign={!isLaptop ? 'center' : 'unset'}
            paddingX={majorScale(4)}
          >
            <Heading
              color="#ffffff"
              fontSize={!isLaptop ? '1.8em' : '4.5em'}
              paddingY={majorScale(2)}
              lineHeight={!isLaptop ? '28px' : '48px'}
            >
              We'll be right back
            </Heading>
            <Paragraph
              color="#ffffff"
              fontSize={!isLaptop ? '1.5em' : '2.5em'}
              lineHeight={!isLaptop ? '28px' : '48px'}
              paddingBottom={majorScale(2)}
            >
              We’re currently updating our services. Thank you for your
              patience.
            </Paragraph>
            <Pane paddingTop={majorScale(2)} display="flex">
              <MaintenanceLogo
                height={isLaptop ? 60 : 30}
                width={isLaptop ? 210 : 105}
              />
            </Pane>
          </Pane>
          <Pane
            position="absolute"
            top={!isLaptop ? 'auto' : '-34px'}
            right={!isLaptop ? '-15px' : '-80px'}
            bottom={!isLaptop ? '-35px' : '0'}
          >
            <Pane
              display="block"
              width={!isLaptop ? '155px' : '460px'}
              height={!isLaptop ? '195px' : '579px'}
            >
              <img
                src={Maintenance}
                width={!isLaptop ? '155px' : '460px'}
                height={!isLaptop ? '195px' : '579px'}
              />
            </Pane>
          </Pane>
        </MaintenanceBanner>
      </Container>
    </Pane>
  );
};

export default MaintenancePage;
