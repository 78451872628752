import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { NavLink, useLocation } from 'react-router-dom';
import { ListItem, UnorderedList, minorScale, majorScale } from 'evergreen-ui';
import { authUserVar, isAuthVar, handleLogout } from '../../../helpers/auth';
import { getProtectedRoutes } from '../auth-header';
import { AUTH_VIEW } from '../../../helpers/enums';
import { WithSubdomainLink } from '../../../hooks/use-subdomain';

const MobileAuthHeader = () => {
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);

  const { pathname } = useLocation();

  return (
    <>
      <UnorderedList
        display="flex"
        flexDirection="column"
        columnGap={majorScale(5)}
      >
        {isAuth ? (
          <UnorderedList>
            {getProtectedRoutes(authUser, pathname)
              .filter((protectedRoute) => !protectedRoute.hide)
              .map((protectedRoute, i) => {
                if (protectedRoute.domainOnly) {
                  return (
                    <ListItem key={i} color="var(--text-default-color)">
                      <WithSubdomainLink
                        exact
                        to={protectedRoute.path}
                        activeStyle={{ color: '#000' }}
                        role="menuitem"
                        style={{
                          display: 'block',
                          padding: '4px 0',
                          color: 'inherit',
                        }}
                      >
                        {protectedRoute.title}
                      </WithSubdomainLink>
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    key={i}
                    color="var(--text-default-color)"
                    fontSize="1em"
                  >
                    <NavLink
                      exact
                      to={protectedRoute.path}
                      activeStyle={{ color: 'var(--text-default-color)' }}
                      role="menuitem"
                      style={{
                        display: 'block',
                        padding: '4px 0',
                        color: 'inherit',
                      }}
                    >
                      {protectedRoute.title}
                    </NavLink>
                  </ListItem>
                );
              })}

            <ListItem
              role="menuitem"
              paddingY={minorScale(1)}
              color="var(--text-default-color)"
              fontSize="1em"
              cursor="pointer"
              onClick={handleLogout}
            >
              Sign Out
            </ListItem>
          </UnorderedList>
        ) : (
          Object.values(AUTH_VIEW).map((authView, i) => {
            return (
              !authView.hide && (
                <ListItem
                  key={i}
                  fontSize="inherit"
                  color="var(--text-default-color)"
                >
                  <WithSubdomainLink
                    exact
                    isNavLink
                    cursor="pointer"
                    to={authView.path}
                    activeStyle={{ color: 'var(--text-default-color)' }}
                    role="menuitem"
                    style={{
                      display: 'block',
                      padding: '4px 0',
                      color: 'inherit',
                    }}
                  >
                    {authView.label}
                  </WithSubdomainLink>
                </ListItem>
              )
            );
          })
        )}
      </UnorderedList>
    </>
  );
};

export default MobileAuthHeader;
