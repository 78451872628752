import styled from '@emotion/styled';
import {
  Dialog,
  Heading,
  ListItem,
  majorScale,
  Pane,
  Paragraph,
  UnorderedList,
} from 'evergreen-ui';
import React from 'react';

const StyledUnorderedList = styled(UnorderedList)`
  list-style: disc;

  li {
    list-style: disc;
    margin-left: 18px !important;
  }
`;

const TermsAndConditions = ({ isOpen, close }) => {
  return (
    <Dialog
      width={600}
      topOffset="10vh"
      hasFooter={false}
      hasCancel={false}
      onCancel={close}
      isShown={isOpen}
      alignSelf="center"
      preventBodyScrolling
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      contentContainerProps={{
        paddingBottom: '20px',
        borderRadius: 0,
      }}
      title={<Heading>Terms and Conditions</Heading>}
    >
      <Pane height="100%">
        <Paragraph>
          These Terms & Conditions may vary from time to time. Please make sure
          you review them regularly as you will be deemed to have accepted a
          variation if you continue to use this website after any variation has
          been posted.
        </Paragraph>
        <Heading marginTop={majorScale(2)}>Conditions of Ticket Sale</Heading>
        <StyledUnorderedList>
          <ListItem>
            Treat your tickets with the same security as your own cash. If
            copies are made, the first ticket scanned at the event will be
            allowed entry. Subsequent scans (whether of the original or copies)
            will not be allowed entry.
          </ListItem>
          <ListItem>Tickets cannot be refunded or exchanged.</ListItem>
          <ListItem>
            We will not take responsibility for the validity of tickets
            purchased from unauthorised agents or other sources. Only purchase
            tickets from a trusted source.
          </ListItem>
          <ListItem>
            We will not be responsible for any ticket that is lost, stolen or
            destroyed. Where possible, we will make every effort to re-issue
            your misplaced ticket, however for some events and venue
            configurations this may not always be possible.
          </ListItem>
          <ListItem>
            Event Owner reserves the right to make alterations to the advertised
            programme at any time and without advance notice.
          </ListItem>
          <ListItem>
            It is your responsibility to check that the event is going ahead at
            the date and time printed on this Ticket. All advertised times are
            approximate and subject to change.
          </ListItem>
          <ListItem>
            Our website and event promoters are not liable for any personal
            travel, accommodation or hospitality arrangements made relating to
            the event or the cancellation of any event. If an event is
            cancelled, we will return the total value of your tickets, including
            any booking fees as soon as reasonably possible.
          </ListItem>
          <ListItem>
            The event owner and / or merchant is fully responsible for any
            physical ticket collection arrangement.
          </ListItem>
          <ListItem>
            In the situation of collecting tickets from a venue box office,
            customers will be required to produce the credit/debit card used for
            the original transaction and/or a valid form of photographic ID to
            redeem their tickets.
          </ListItem>
          <ListItem>
            There will be no admission to events before the designated opening
            times on the tickets. For some shows latecomers may not be admitted
            until a suitable break in the performance.
          </ListItem>
          <ListItem>
            A valid ticket must be produced to get into an event. Removing any
            part of, altering or defacing the ticket may invalidate your ticket.
          </ListItem>
          <ListItem>
            It is your responsibility to check your order/tickets are correct
            and contact us immediately if there is a mistake. We will not be
            responsible for any issues that occurred as result of the booker’s
            mistake. Any known issues with your order that have occurred as a
            result of the venue will be communicated to you as soon as
            reasonably possible.
          </ListItem>
          <ListItem>
            Event Owner reserves the right to provide alternative seats of the
            same price range to those specified on your ticket where it is not
            possible to allow you to occupy the specific seat printed on your
            ticket.
          </ListItem>

          <ListItem>
            If seating blocks or exact seats are not stated for any ticket type
            at the time of purchase, the event owner reserves the right to
            allocate the available seats in the price range purchased and
            tickets are not eligible for refund. By completing a purchase, the
            buyer is accepting to be allocated seats by the Host or Event owner.
          </ListItem>
          <ListItem>
            An order is confirmed once you have received online confirmation and
            an order confirmation email. We will get in touch to let you know if
            there are any issues with your order and will proceed to help
            rectify this for you, subject to availability.
          </ListItem>
          <ListItem>
            Tickets will only be emailed or delivered to the delivery address
            entered at the time of booking. Except where clearly entered
            wrongly, email or delivery addresses cannot be changed once an order
            is processed.
          </ListItem>
          <ListItem>
            It is the booker’s responsibility to ensure the email or delivery
            address is correct at the time of booking.
          </ListItem>
          <ListItem>
            Unauthorised photography or recording may not be permitted and your
            equipment may be seized and content recorded destroyed if you are
            not authorized by the organizers.
          </ListItem>
          <ListItem>
            In order to ensure the security, safety and comfort of all patrons,
            the organizers reserve the right at reasonable discretion to refuse
            admission to or to eject you from the venue without compensation or
            refund.
          </ListItem>
          <ListItem>
            Tickets for events are sold for a performance, (where it is an
            artiste) by the headline artist only. Event owner reserves the right
            to make amendments to the advertised guest/support artists without
            prior notice or liability. No refunds or compensation will be issued
            for changes to the guest/support act.
          </ListItem>
          <ListItem>
            Any queries regarding the terms and conditions of sale should be
            raised with the tickets purchase source.
          </ListItem>
          <ListItem>
            Ticket pricing is subject to change at any time without notice and
            is only fixed at the time of booking. Discounts/offers cannot be
            applied retrospectively.
          </ListItem>
          <ListItem>
            Failure to comply with these Terms & Conditions will make your
            ticket and transaction void.
          </ListItem>
        </StyledUnorderedList>
      </Pane>
    </Dialog>
  );
};

export default TermsAndConditions;
