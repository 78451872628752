import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  Button,
  minorScale,
  UnorderedList,
  ListItem,
  toaster,
} from 'evergreen-ui';
import get from 'lodash/get';

import styled from '@emotion/styled';
import { Formik, Field } from 'formik';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Btn from '../../btn/btn';
import {
  PromoterSuccessType,
  VIEWPORT_BREAKPOINTS,
} from '../../../helpers/enums';
import {
  authUserVar,
  setAuthUser,
  tokenVar,
  setIsAuth,
} from '../../../helpers/auth';
import useResponsiveness from '../../../hooks/use-responsiveness';
import FormRadioGroup from '../../form-radio-group/form-radio-group';
import FormCheckbox from '../../form-checkbox/form-checkbox';
import apiManager from '../../../helpers/apiManager';
import { subdomainUrl } from '../../../hooks/use-subdomain';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copyIcon.svg';
import Container from '../../container/container';
import { GET_HOSTNAME_QUERY } from '../../../features/host/graphql/queries';

const EventImageBlurredBanner = styled(Pane)`
  background-color: var(--secondary-dark-color);
  filter: blur(1rem);
  background: ${(props) =>
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${props.image}') no-repeat center`};
  background-size: 100% 100%;
  height: 233px;
  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    height: 172px;
  }
`;

const EventImageBanner = styled(Pane)`
  background-color: var(--secondary-dark-color);
  background: ${(props) =>
    `linear-gradient(to bottom, 
    rgba(217, 217, 217, 0) 16.51%, rgba(6, 48, 77, .8)), url('${props.image}') no-repeat center`};
  background-size: cover;
  height: 233px;
  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    height: 172px;
  }
`;

const FORM_SCHEMA = yup.object().shape({
  eventsAllow: yup.boolean().required('Event Type is required'),

  isNotificationsEnable: yup
    .boolean()
    .required('Notification is required')
    .default(true)
    .nullable(),
});

const CloseButton = styled(Button)`
  position: absolute;
  right: 20px;
  border-radius: 50%;
  padding: 25px 18px;
  top: 15px;
  font-size: 1.5em;
  opacity: 0.7;
  border: none;

  &:hover {
    border: none !important;
  }
`;

const HostLoginSuccess = ({
  isShown,
  setIsShown,
  EventTitle,
  id,
  image,
  commission,
  isPromoter,
  refetch,
}) => {
  const { isLaptop, isSmallScreen } = useResponsiveness();

  const authUser = useReactiveVar(authUserVar);
  const history = useHistory();
  const token = tokenVar();

  const [loader, setLoader] = React.useState();

  const { mutateAsync: datas } = useMutation(
    apiManager.JoinTheEventForPromotionAsync
  );

  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  const JoinPromoterEvents = async (values) => {
    try {
      setLoader(true);

      const { data, message } = await datas({
        ...values,
        eventReferenceNumber: Number(id),
        hostId: authUser.hostId,
      });
      setIsAuth(token);
      setAuthUser({
        ...authUser,
      });
      if (data) {
        setLoader(false);
        // refetch();
        history.push({
          pathname: '/host/dashboard',
          state: { EventId: authUser.hostId },
        });

        setIsShown(false);
        toaster.success(
          message || 'Thanks for showing interest to promote the events'
        );
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  const { data: hostLogoName, loading } = useQuery(GET_HOSTNAME_QUERY, {
    variables: { where: { companyId: { eq: authUser.hostId } } },
  });

  const data = get(hostLogoName, 'Company.Company[0]', false);

  const handleCopyClick = async () => {
    const textToCopy = subdomainUrl(data?.CompanyName);
    try {
      await navigator.clipboard.writeText(textToCopy);
      toaster.success('Successful Copy to Clipboad');
    } catch (error) {
      toaster.danger('UnsuccessfullCopy to Clipboad');
    }
  };

  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      hasFooter={false}
      contentContainerProps={{ padding: 0 }}
      hasHeader={false}
      width="784px"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      preventBodyScrolling={true}
    >
      <Formik
        validationSchema={FORM_SCHEMA}
        initialValues={{ eventsAllow: false, isNotificationsEnable: true }}
        onSubmit={(values) => {
          const { eventsAllow, ...rest } = values;
          JoinPromoterEvents({
            chooseEventsManually: eventsAllow ? true : false,
            showAllEvents: eventsAllow ? false : true,
            ...rest,
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <>
              <Pane
                marginBottom={!isLaptop && majorScale(2)}
                width="100%"
                position="relative"
              >
                <EventImageBlurredBanner image={image} />
                <Pane
                  width="100%"
                  height="100%"
                  position="absolute"
                  top="0"
                  display="flex"
                  flexDirection="column"
                >
                  <Container
                    maxWidth={1000}
                    height="100%"
                    paddingTop={minorScale(2)}
                  >
                    <EventImageBanner image={image}></EventImageBanner>
                  </Container>
                </Pane>
              </Pane>
              {!isPromoter && (
                <CloseButton onClick={() => setIsShown(false)}>X</CloseButton>
              )}
              <Pane
                paddingX={isLaptop ? majorScale(15) : majorScale(1)}
                paddingY={isLaptop ? majorScale(6) : majorScale(2)}
                textAlign={isLaptop ? 'center' : 'left'}
              >
                <Heading fontSize="1.5em" marginBottom={majorScale(1)}>
                  The {EventTitle} Event will show on your subdomain
                </Heading>

                {authUser?.hostId && (
                  <>
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Heading fontSize="1em" fontWeight="700">
                        {subdomainUrl(data?.CompanyName)}
                      </Heading>

                      <Btn look="primary-minimal" onClick={handleCopyClick}>
                        <CopyIcon />
                      </Btn>
                    </Pane>
                    <Paragraph>
                      share your subdomain with potential customers and earn{' '}
                      {commission ? `${commission}% ` : ''} on every ticket sale
                    </Paragraph>
                  </>
                )}

                <>
                  <form onSubmit={handleSubmit}>
                    <UnorderedList
                      alignItems="center"
                      paddingLeft={isLaptop ? majorScale(10) : 0}
                    >
                      <ListItem>
                        <Pane>
                          <Field name="eventsAllow">
                            {({ field, form, meta }) => {
                              return (
                                <Pane marginBottom={majorScale(3)}>
                                  <FormRadioGroup
                                    size={majorScale(2)}
                                    value={field.value}
                                    options={Object.values(PromoterSuccessType)}
                                    orientation="vertical"
                                    isInvalid={meta.touched && !!meta.error}
                                    validationMessage={
                                      meta.touched && meta.error
                                    }
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                  />
                                </Pane>
                              );
                            }}
                          </Field>
                        </Pane>
                      </ListItem>
                      <ListItem>
                        <Pane marginBottom={majorScale(3)}>
                          <Field name="isNotificationsEnable">
                            {({ field, meta }) => {
                              return (
                                <>
                                  <FormCheckbox
                                    marginBottom={majorScale(3)}
                                    color="var(--primary-default-color)"
                                    size={minorScale(5)}
                                    name={field.name}
                                    checked={field.value}
                                    label="Notify me about event from this host/organiser"
                                    onChange={handleChange}
                                  />
                                  <Pane>
                                    <Paragraph
                                      letterSpacing={0}
                                      color="#D14343"
                                      lineHeight="18px"
                                      fontSize="13px"
                                      fontWeight={400}
                                      marginBottom={0}
                                    >
                                      {meta.touched && meta.error}
                                    </Paragraph>
                                  </Pane>
                                </>
                              );
                            }}
                          </Field>
                        </Pane>
                      </ListItem>
                    </UnorderedList>

                    <Pane display="flex" justifyContent="center">
                      <Btn
                        width="200px"
                        fontSize="1em"
                        type="submit"
                        isLoading={loader}
                      >
                        Join
                      </Btn>
                    </Pane>
                  </form>
                </>
              </Pane>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default HostLoginSuccess;
