import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { toaster, minorScale, majorScale, Text } from 'evergreen-ui';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Btn from '../../../components/btn/btn';
import { getErrorMessage } from '../../../helpers/functions';
import { UserIpAddressContext } from '../../../providers/user-ipaddress';
import { setIsAuth, parseJwt, setAuthUser } from '../../../helpers/auth';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-blue.svg';

const FB_AUTH_MUTATION = gql`
  mutation FbAuthMutation($access_token: String!) {
    facebook_auth(access_token: $access_token) {
      access_token
    }
  }
`;

const FacebookAuth = ({ callback, isSignup }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { getLocationId } = React.useContext(UserIpAddressContext);

  const history = useHistory();

  const [mutate] = useMutation(FB_AUTH_MUTATION);

  async function handleFacebookAuth(response) {
    try {
      setIsLoading(true);

      if (response.accessToken) {
        const { data } = await mutate({
          variables: {
            access_token: response.accessToken,
          },
        });

        if (data) {
          const accessToken = data.facebook_auth.access_token;
          setIsAuth(accessToken);

          const authUser = parseJwt(accessToken);
          setAuthUser(authUser);

          if (authUser.country) {
            getLocationId(authUser.country);
          }

          toaster.success(
            isSignup ? 'Account successfully created.' : 'Signin successful.'
          );

          if (callback) {
            callback();
            return;
          }
          history.push('/');
        }
      }
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      callback={handleFacebookAuth}
      render={(renderProps) => (
        <Btn
          width="100%"
          minWidth="130px"
          fontSize="1em"
          type="button"
          look="tertiary-outlined"
          alignItems="center"
          justifyContent="space-between"
          height={majorScale(6)}
          columnGap={minorScale(2)}
          marginBottom={majorScale(3)}
          isLoading={isLoading}
          onClick={renderProps.onClick}
        >
          <FacebookIcon width={24} />
          <Text fontWeight="bold">Facebook</Text>
        </Btn>
      )}
    />
  );
};

export default FacebookAuth;
