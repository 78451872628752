import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Link,
  Paragraph,
  Text,
  Heading,
} from 'evergreen-ui';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { useReactiveVar } from '@apollo/client';
import { Formik } from 'formik';
import { authUserVar } from '../../../helpers/auth';
import FormInput from '../../form-input/form-input';
import Btn from '../../btn/btn';

const DialogStyle = styled(Dialog)`
  margin: 0;
`;

const INIT_VALUES = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  confirmEmail: '',
};

const APPLESIGN_PERSONAL_INFO_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .matches(
      /^[A-Za-z][A-Za-z\s]*$/,
      'The First name must contain only alphabets.'
    )
    .required('First name is required.'),
  lastName: yup
    .string()
    .matches(
      /^[A-Za-z][A-Za-z\s]*$/,
      'The Last name must contain only alphabets.'
    )
    .required('Last name is required.'),
  emailAddress: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address')
    .test('privateAppleId', 'Private Apple IDs are not allowed', (value) => {
      return !value || !value.includes('@privaterelay.appleid.com');
    }),
  confirmEmail: yup
    .string()
    .trim()
    .required('Confirm Email is required')
    .email('Invalid confirm email address')
    .test('confirmEmail', 'Email must match', (value, context) => {
      return context.parent.emailAddress === value;
    }),
});

const AppleSigninPersonalInfoForm = ({ handleAppleUserUpdate, loader }) => {
  const authUser = useReactiveVar(authUserVar);

  const [appleUserName, setAppleUserName] = React.useState(INIT_VALUES);

  React.useEffect(() => {
    if (authUser) {
      setAppleUserName(() => ({
        ...INIT_VALUES,
        firstName: authUser?.firstName ? authUser?.firstName : '',
        lastName: authUser?.lastName ? authUser?.lastName : '',
        ...(!authUser?.email.includes('@privaterelay.appleid.com')
          ? { emailAddress: authUser?.email }
          : {}),
        ...(!authUser?.email.includes('@privaterelay.appleid.com')
          ? { confirmEmail: authUser?.email }
          : {}),
      }));
    }
  }, [authUser]);

  return (
    <Pane>
      <DialogStyle
        isShown={true}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        hasFooter={false}
        hasHeader={false}
        containerProps={{
          className: 'appleLoginPromoter',
        }}
      >
        <Pane paddingBottom={majorScale(2)}>
          <Formik
            validationSchema={APPLESIGN_PERSONAL_INFO_SCHEMA}
            initialValues={appleUserName}
            onSubmit={(values) => {
              handleAppleUserUpdate(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Pane position="relative">
                    <Heading
                      marginTop={majorScale(3)}
                      marginBottom={majorScale(1)}
                    >
                      Customer details
                    </Heading>
                    <Paragraph lineHeight="18px" marginBottom={majorScale(3)}>
                      We could not access your basic information when you signed
                      in or signed up using your Apple ID. To continue, please
                      provide us with this basic information.
                    </Paragraph>
                  </Pane>
                  {/* <Heading
                  position="absolute"
                  top="14px"
                  right="20px"
                  font-size="20px"
                  cursor="pointer"
                  onClick={() => onClose(false)}
                >
                  X
                </Heading> */}
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="First Name *"
                    name="firstName"
                    placeholder="Enter First name"
                    value={values.firstName}
                    isInvalid={touched.firstName && !!errors.firstName}
                    validationMessage={touched.firstName && errors.firstName}
                    onChange={handleChange}
                  />
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Last Name *"
                    name="lastName"
                    placeholder="Enter Last name"
                    value={values.lastName}
                    isInvalid={touched.lastName && !!errors.lastName}
                    validationMessage={touched.lastName && errors.lastName}
                    onChange={handleChange}
                  />
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    onPaste={(e) => e.preventDefault()}
                    label="Email *"
                    inputMode="email"
                    name="emailAddress"
                    placeholder="Enter email"
                    disabled={
                      authUser?.email &&
                      !authUser?.email?.includes('@privaterelay.appleid.com')
                    }
                    value={values.emailAddress}
                    isInvalid={touched.emailAddress && !!errors.emailAddress}
                    validationMessage={
                      touched.emailAddress && errors.emailAddress
                    }
                    onChange={handleChange}
                  />
                  {authUser?.email?.includes('@privaterelay.appleid.com') && (
                    <FormInput
                      marginBottom={majorScale(2)}
                      inputHeight={majorScale(5)}
                      onPaste={(e) => e.preventDefault()}
                      label="Confirm Email *"
                      inputMode="email"
                      name="confirmEmail"
                      placeholder="Enter Confirm email"
                      value={values.confirmEmail}
                      isInvalid={touched.confirmEmail && !!errors.confirmEmail}
                      validationMessage={
                        touched.confirmEmail && errors.confirmEmail
                      }
                      onChange={handleChange}
                    />
                  )}
                  {/* TODO:we will review the performance of the website after a few months of use */}
                  {/* <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => setRecaptchaToken(value)}
                  /> */}

                  <Btn
                    type="submit"
                    width="50%"
                    fontSize="1em"
                    isLoading={loader}
                    marginY="0"
                    marginX="auto"
                  >
                    Continue
                  </Btn>
                </form>
              );
            }}
          </Formik>
        </Pane>
      </DialogStyle>
    </Pane>
  );
};

export default AppleSigninPersonalInfoForm;
