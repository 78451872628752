const loadGoogleRecaptchaAPI = (callback) => {
  const scriptName = 'google-recaptcha-api';

  const existingScript = document.getElementById(scriptName);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.type = 'text/javascript';
    script.id = scriptName;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export default loadGoogleRecaptchaAPI;
