import * as React from 'react';
import get from 'lodash/get';
import { gql, useSubscription } from '@apollo/client';

import useTokenExpiry from '../../hooks/use-token-expiry';
import LoadingStripe from '../../components/loading-state-spinner/laoding-stripe';
import MaintenancePage from '../../components/maintenance-page/maintenance-page';
import { convertToBoolean } from '../../helpers/functions';
import { wsClient } from '../../libs/apollo-client';

const MAINTENANCE_SUBSCRIPTION = gql`
  subscription {
    onPublished {
      id
      status
    }
  }
`;

function MaintenancProtectedRoute({ component: Component }) {
  useTokenExpiry();
  const [isMaintenance, setMaintenance] = React.useState(false);

  const { loading } = useSubscription(MAINTENANCE_SUBSCRIPTION, {
    onError: () => {
      setMaintenance(true);
    },
    onSubscriptionData: ({ subscriptionData }) => {
      setMaintenance(get(subscriptionData, 'data.onPublished.status', false));
    },
  });

  // React.useEffect(() => {
  //   const handleWebSocketError = () => {
  //     setMaintenance(true);
  //   };
  //   wsClient.onError(handleWebSocketError);
  // }, [wsClient]);

  if (convertToBoolean(process.env.REACT_APP_MANITENANCE_PAGE)) {
    return <MaintenancePage />;
  }

  if (loading && !isMaintenance) {
    return <LoadingStripe />;
  }

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  if (!isMaintenance) {
    return Component;
  }

  return <LoadingStripe />;
}

export default MaintenancProtectedRoute;
