const loadCloudianryWidget = (callback) => {
  const widgetScriptName = 'cloudinary-widget';

  const existingScript = document.getElementById(widgetScriptName);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.type = 'text/javascript';
    script.id = widgetScriptName;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadCloudianryWidget;
