import axios from 'axios';
import * as React from 'react';

import useCountriesHomePage, {
  defaultCountry,
} from '../hooks/use-countries-homepage';

const UserIpAddressContext = React.createContext();

function UserIpAddressProvider(props) {
  const { data } = useCountriesHomePage();

  // default id of United Kingdom is used here
  const [locationId, setLocationId] = React.useState(9);

  const [ipAddress, setIpAddress] = React.useState('');

  // default array containing UK data
  const [countries, setCountries] = React.useState([defaultCountry]);

  React.useEffect(() => {
    getLocationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  React.useEffect(() => {
    if (data?.CountryDetails?.CountryDetails?.length) {
      setCountries(data.CountryDetails?.CountryDetails);
    }
  }, [data?.CountryDetails]);

  function getLocationId(location) {
    const country = countries.filter(
      (country) =>
        location === country.ISO2 || location === country.CountryDescription
    );
    if (country.length) setLocationId(country[0].CountryId);
    else setLocationId(9);
  }

  // fetch
  async function getLocationDetails() {
    const apis = [
      {
        url: 'https://ipapi.co/json/',
        parse: (data) => ({ ip: data.ip, country: data.country_code }),
      },
      {
        url: 'https://ipinfo.io/json',
        parse: (data) => ({ ip: data.ip, country: data.country }),
      },
      {
        url: 'https://api.db-ip.com/v2/free/self',
        parse: (data) => ({ ip: data.ipAddress, country: data.countryCode }),
      },
      {
        url: 'https://freegeoip.app/json/',
        parse: (data) => ({ ip: data.ip, country: data.country_code }),
      },
    ];

    for (const api of apis) {
      try {
        // Try the current API
        const res = await axios.get(api.url);
        if (res && res.data) {
          const { ip, country } = api.parse(res.data);
          setIpAddress(ip); // Update your state or logic
          getLocationId(country); // Handle country code
          return; // Exit once successful
        } else {
          throw new Error(`No valid response from ${api.url}`);
        }
      } catch (error) {
        console.error(`Error with API ${api.url}:`, error.message);
      }
    }

    console.error('All APIs failed.');
  }

  const value = React.useMemo(
    () => ({ countries, locationId, getLocationId, ipAddress }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countries, locationId]
  );

  return <UserIpAddressContext.Provider value={value} {...props} />;
}

export { UserIpAddressProvider as default, UserIpAddressContext };
