import React from 'react';
import { Dialog, Paragraph, Button } from 'evergreen-ui';
import styled from '@emotion/styled';

const CloseButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: -2px;
  font-size: 14px;
  background: none;
  opacity: 0.7;
  border: none;

  &:hover {
    border: none !important;
    background: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

const PresaleLiveModel = ({ preSaleStarted }) => {
  const [isShown, setIsShown] = React.useState(false);
  React.useEffect(() => {
    if (preSaleStarted) {
      setIsShown(preSaleStarted);
    }
  }, [preSaleStarted]);
  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      hasFooter={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasHeader={false}
    >
      <Paragraph position="relative">
        Get your tickets early for this event! The presale is on now, but not
        for long. Sign up on the waitlist to join the presale. Don’t wait;
        secure your spot and have fun!
      </Paragraph>
      <CloseButton
        onClick={() => setIsShown(false)}
        textAlign="end"
        cursor="pointer"
        fontSize="1em"
      >
        X
      </CloseButton>
    </Dialog>
  );
};
export default PresaleLiveModel;
