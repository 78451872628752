import * as React from 'react';
import { Pane, Dialog } from 'evergreen-ui';

import { AUTH_VIEW } from '../../../helpers/enums';
import SigninModal from '../signin-modal/signin-modal';
import SignupModal from '../signup-modal/signup-modal';
import ForgotPasswordModal from '../forgot-password-modal/forgot-password-modal';

const AuthModal = ({ isOpen, callback, closeModal }) => {
  const [currentAuthView, setCurrentAuthView] = React.useState(
    AUTH_VIEW.SIGNIN.value
  );

  const renderBasedOnView = () => {
    switch (currentAuthView) {
      case 'SIGNIN':
        return (
          <SigninModal
            callback={callback}
            closeModal={closeModal}
            setCurrentAuthView={setCurrentAuthView}
          />
        );

      case 'SIGNUP':
        return (
          <SignupModal
            closeModal={closeModal}
            setCurrentAuthView={setCurrentAuthView}
          />
        );

      case 'FORGOT_PASSWORD':
        return (
          <ForgotPasswordModal
            closeModal={closeModal}
            setCurrentAuthView={setCurrentAuthView}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Dialog
      width={960}
      topOffset="10vh"
      hasFooter={false}
      hasHeader={false}
      hasCancel={false}
      sideOffset="0"
      minHeightContent="60vh"
      alignSelf="center"
      preventBodyScrolling
      shouldCloseOnOverlayClick={false}
      isShown={isOpen}
      contentContainerProps={{
        paddingY: 0,
        borderRadius: 0,
      }}
    >
      <Pane height="100%">{renderBasedOnView()}</Pane>
    </Dialog>
  );
};

export default AuthModal;
