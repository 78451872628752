import sc from 'states-cities-db';
import _ from 'lodash';
import * as yup from 'yup';

import { getTimezoneByCountry } from 'country-timezone-list';
import { Buffer } from 'buffer';
import { titleCase as titleCasePackage } from 'title-case';
import currency from 'currency.js';
import { toaster } from 'evergreen-ui';
import { EXPIRED_JWT_ERROR_MESSAGE } from '../constants';
import { DateTime } from 'luxon';
import { CURRENCY_SYMBOLS } from './enums';
import abbreviation from './abbreviation.json';

export function formatFileSize(totalBytes) {
  if (totalBytes < 1000000) {
    return Math.floor(totalBytes / 1000) + 'KB';
  }

  return Math.floor(totalBytes / 1000000) + 'MB';
}

export function xhrRequest({
  method,
  url,
  data,
  onProgress,
  onReadyStateChange,
  // onCancel,
}) {
  let xhr = new XMLHttpRequest();

  xhr.open(method, url, true);

  xhr.upload.onprogress = onProgress;

  xhr.onreadystatechange = onReadyStateChange;

  // xhr.upload.onabort = onCancel;

  xhr.send(data);
  return xhr;
}

export const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getBase64Url(file, callback) {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => {
    const result = reader.result.substring(23);
    // const result = reader.result.replace('data:image/png;base64,', '');
    callback(null, result);
  };
  reader.onerror = (error) => {
    callback(error);
  };
}

export function titleCase(str) {
  if (!str) return '';

  return titleCasePackage(String(str).toLowerCase());
}

export function formatDate(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(new Date(datetime));
}

export function formatDatetime(datetime, dateStyle) {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: dateStyle ?? 'medium',
    timeStyle: 'short',
    hour12: false,
  }).format(new Date(datetime));
}

export function formatEventDate(datetime, withoutDay) {
  if (withoutDay)
    return new Intl.DateTimeFormat('en-GB', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(datetime));

  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(new Date(datetime));
}

export const transactionsToCurrency = (x = 0, currency = '€') => {
  const _x = typeof x === 'string' ? Number(x) : x;

  const result = _x
    ? _x.toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      })
    : '0.00';

  return `${currency} ${result}`;
};

export function formatEventTime(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
    timeZone: 'Africa/Lagos',
    timeZoneName: 'short',
  }).format(new Date(datetime));
}

export function formatFullDate(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(new Date(datetime));
}

export function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}

export function getErrorMessage(error, id = 'Error Message') {
  let errorMessage;

  if (error?.graphQLErrors?.length > 0) {
    errorMessage = error.graphQLErrors[0].message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage, { id });
    return errorMessage;
  }

  if (error.message) {
    errorMessage = error.message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage, { id });
    return errorMessage;
  }

  errorMessage = 'Something went wrong. Please try again.';
  if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
  toaster.danger(errorMessage, { id });
  return errorMessage;
}

export function ISOFormatTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error(`Invalid date`);
  }

  const isoString = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  ).toISOString();

  return isoString;
}

export function getHttpErrorMessage(error) {
  let errorMessage;

  if (error?.response) {
    // The request was made
    // the server responded with a status code that falls out of the range of 2xx
    errorMessage = error.response.data.message || error.response.data.error;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }

  if (error?.request) {
    // The request was made but no response was received
    errorMessage = 'Something went wrong';
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }
}

export function optionsToReactSelectFormat(
  options = [],
  key = { label: '', value: '' }
) {
  if (key.label && key.value) {
    return options.map((option) => ({
      label: option[key.label],
      value: option[key.value],
    }));
  }

  throw new Error(
    'optionsToReactSelectFormat function must contain a "key" parameter with "label" and "value" properties'
  );
}

export const generateShortId = (length = 6) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZ';
  let str = '';

  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }

  return str;
};

export const numberToCurrency = (x = 0, currencyIso3 = '£', hide = false) => {
  // Convert to number using Lodash
  const _x = _.toNumber(_.replace(x, /[^0-9.-]/g, ''));

  // Check if the conversion is valid
  if (_.isNaN(_x)) {
    return hide ? '0.00' : `${currencyIso3} 0.00`;
  }

  // Check if the number is negative
  const isNegative = _x < 0;

  // Get the absolute value for formatting
  const absValue = Math.abs(_x);

  // Format the number with commas
  const formattedNumber = absValue
    ? absValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '0.00';

  // Reapply the negative sign if necessary
  const result = isNegative ? `-${formattedNumber}` : formattedNumber;

  // Return the formatted currency string
  return hide ? result : `${currencyIso3} ${result}`;
};

export function convertToFloatOrNull(value) {
  // Check if the value is a number
  if (typeof value === 'number') {
    return null;
  }

  // Check if the value is a string
  if (typeof value === 'string') {
    // Check if the string contains a decimal point
    if (value.includes('.')) {
      // Try to parse the string as a float
      const floatValue = parseFloat(value);

      // Check if the parsing was successful and the result is a finite number
      if (!isNaN(floatValue) && isFinite(floatValue)) {
        return floatValue;
      }
    }
  }

  // If the value is neither a number nor a string containing a float, return null
  return null;
}

export const getAgeCriteriaOptions = () => {
  let ageOptionsArray = [];

  for (let i = 1; i < 100; i++) {
    ageOptionsArray.push({ label: i + '+', value: i });
  }

  return ageOptionsArray;
};

export const textToBoolean = (text) => {
  const trueTexts = ['true', 'yes', 'y'];
  const falseTexts = ['false', 'no', 'n'];

  let boolVal = false;

  if (text) {
    text = text.toLowerCase();
    if (trueTexts.includes(text)) {
      boolVal = true;
    } else if (falseTexts.includes(text)) {
      boolVal = false;
    }
  }

  return boolVal;
};

export const eventCardTextToEllipses = (text = '', len = 60) => {
  if (text === text.toUpperCase()) {
    len = 48;
  } else if (text === text.toLowerCase()) {
    len = 68;
  }

  if (text.length < len) {
    return text;
  }

  return `${text.trim().substring(0, len)}...`;
};

export const textToEllipses = (text = '', len = 20) => {
  if (text.length > len) {
    return `${text.trim().substring(0, len)}...`;
  }

  return text;
};

export const currencyToSymbol = (currency = '') => {
  if (currency) {
    return CURRENCY_SYMBOLS[currency.toUpperCase()];
  }
  return undefined;
};

export const sortEventStartDate = (data) => {
  data.sort((event1, event2) => {
    let date1 = event1.EventDateFrom;
    let date2 = event2.EventDateFrom;

    if (date1 > date2) {
      return 1;
    }
    if (date1 < date2) {
      return -1;
    }
    return 0;
  });

  return data;
};

export const compareArrayOrObjects = (param1, param2) => {
  return JSON.stringify(param1) === JSON.stringify(param2);
};

export function filterObject(obj, callback) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  );
}

export const filterArrayOfObjects = (array, callback) => {
  return array.filter((elem) => callback(elem));
};

// --> text case conversion
export const convertCamelToSentence = (value) => {
  const result = value.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeCaseToSentence = (string) =>
  string
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

// Function to get time zone details based on a time zone identifier
export const getTimeZoneObj = (timeZone) => {
  if (!timeZone) {
    return null;
  }

  const dateTime = DateTime.now().setZone(timeZone);

  const longTimeZoneName = dateTime.offsetNameLong;

  // Helper function to get abbreviation from a sentence
  const getAbbreviation = (sentence) => {
    return _.chain(sentence)
      .split(' ')
      .map((word) => word[0])
      .join('')
      .value();
  };

  return {
    abbr: getAbbreviation(longTimeZoneName),
    name: longTimeZoneName,
    timezone: timeZone,
  };
};

// get timezone by country
export const getTimezonenameByCountry = (countryIso2 = 'GB', timezone) => {
  const tzs = getTimezoneByCountry(countryIso2);
  if (!tzs.length) return null;

  const getAbbreviation = (tz) =>
    tz.abbreviation === tz.offset
      ? abbreviation[tz.alternativeName] || getTimeZoneObj(timezone)?.abbr
      : tz.abbreviation;

  const lastTz = tzs[tzs.length - 1];
  const defaultTz = {
    abbr: getAbbreviation(lastTz),
    name: lastTz.alternativeName,
    timezone: lastTz.name,
  };

  if (timezone) {
    const filteredTz = _.filter(tzs, (item) => item.name === timezone);
    const timezoneObj = getTimeZoneObj(timezone);
    if (!filteredTz.length) {
      return _.isEmpty(timezoneObj) ? defaultTz : timezoneObj;
    }

    const lastFilteredTz = filteredTz[filteredTz.length - 1];
    return {
      abbr: getAbbreviation(lastFilteredTz),
      name: lastFilteredTz.alternativeName,
      timezone: lastFilteredTz.name,
    };
  }

  return defaultTz;
};

// Local timezone
export const longTimeZoneName = new Intl.DateTimeFormat('en-US', {
  timeZoneName: 'long',
})
  .formatToParts(new Date())
  .find(({ type }) => type === 'timeZoneName').value;

export const abbreviatedTimeZoneName = longTimeZoneName
  .split(' ')
  .map((word) => word[0])
  .join('');

export function formatEnum(enumValue) {
  return titleCase(
    String(enumValue ?? '')
      .replaceAll('_', ' ')
      .toLowerCase()
  );
}

export function iso3ToIso2(iso3) {
  return sc.getCountries().filter(({ iso }) => iso === iso3)?.[0]?.iso2;
}

export function iso2ToIso3(iso2) {
  return sc.getCountries().filter(({ iso2: iso }) => iso === iso2)?.[0]?.iso;
}

export function calculateAdminFee(
  price,
  adminPercentage,
  fixedFee,
  currencyShortcode = 'GBP'
) {
  const adminFee = currency(adminPercentage, { precision: 4 })
    .divide(100)
    .multiply(price)
    .add(fixedFee).value;

  return {
    value: adminFee,
    withCurrency: numberToCurrency(
      adminFee,
      currencyToSymbol(currencyShortcode)
    ),
  };
}

// convert event date by timezone
export const setDateToTimeZone = (date = new Date(), removeZone = false) => {
  if (date === null || date === undefined || date === '') return date;
  const dateString = removeTimeZone(new Date(date));

  const isoString = new Date(dateString).toISOString();
  const dateTime = DateTime.fromISO(isoString, { zone: 'utc' }).toISO();

  if (removeZone) {
    return new Date(new Date(dateTime).toISOString().split('.')[0]);
  }
  return dateTime;
};

// format event time by timezone
export const formatEventTimeZone = (time = new Date()) => {
  if (time === null || time === undefined || time === '') return time;

  const toISOString = new Date(time).toISOString();
  return DateTime.fromISO(toISOString, { zone: 'utc' }).toLocaleString(
    DateTime.TIME_24_SIMPLE
  );
};

// Wl represents with-luxon
export const formatDatetimeWl = (time, withoutDay, format) => {
  if (time === null || time === undefined || time === '') return time;
  const toISOString = new Date(time).toISOString();

  const dateWithTimezone = DateTime.fromISO(toISOString, { zone: 'utc' });
  if (format) {
    return dateWithTimezone.toLocaleString(DateTime[format]);
  }
  if (withoutDay) {
    return dateWithTimezone.toLocaleString(DateTime.DATE_FULL);
  }

  return dateWithTimezone.toFormat('DD, T');
};

export const formatDateToOwnFormatWl = (date, format = 'yyyy LLL dd') => {
  if (date === null || date === undefined || date === '') return date;
  const dateString = removeTimeZone(new Date(date));
  const toISOString = new Date(dateString).toISOString();

  const dateWithTimezone = DateTime.fromISO(toISOString, { zone: 'utc' });
  return dateWithTimezone.toFormat(format);
};

export const removeTimeZone = (date) => {
  const opts = {
    zone: 'utc',
    locale: 'en-UK',
  };
  return DateTime.fromFormat(date.toLocaleString('en-UK'), 'F', opts).toISO();
};

export const compareTzDates = (value, countryIso2 = 'GB', _timezone) => {
  const { timezone } = getTimezonenameByCountry(countryIso2, _timezone);
  if (!timezone || !value) return false;
  const targetTimezone = _timezone ? _timezone : timezone;
  return (
    DateTime.now().setZone(targetTimezone).toISO().split('.')[0] <
    DateTime.fromISO(value.toISOString()).toISO().split('.')[0]
  );
};

export const compareDates = (value1, value2, operator = 'less than') => {
  let leftValue = new Date(value1);
  let rightValue = new Date(value2);

  if (!leftValue || !rightValue) return false;
  if (operator === 'less than') return leftValue < rightValue;
  if (operator === 'greater than') return leftValue > rightValue;
  if (operator === 'equal to') return leftValue === rightValue;
  if (operator === 'not equal to') return leftValue !== rightValue;
  if (operator === 'less than or equal to') return leftValue <= rightValue;
  if (operator === 'greater than or equal to') return leftValue >= rightValue;

  return true;
};

export const getCurrentTzDate = (countryIso2 = 'GB', _timezone) => {
  const { timezone } = getTimezonenameByCountry(countryIso2, _timezone);
  if (!timezone) return null;
  const targetTimezone = _timezone ? _timezone : timezone;
  const dateTime = DateTime.now().setZone(targetTimezone).toISO().split('.')[0];

  return dateTime;
};

export const calculateQuantityAvailable = (
  quantity,
  lockedTickets,
  soldTicketsCount
) => {
  const available = Math.max(
    0,
    Number(quantity) -
      (Math.max(0, Number(lockedTickets)) + Number(soldTicketsCount))
  );

  return available;
};

export function coerceBooleanString(value) {
  if (typeof value === 'string') {
    return value === 'true' ? true : false;
  }

  return value === true ? 'true' : 'false';
}

export const backDate = (date) => {
  const now = new Date();

  if (date === '7') {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  }

  if (date === '30') {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
  }
  if (date === 'PAST YEAR')
    return new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
};

export const forceKeyPressUppercase = (e) => {
  let el = e.target;
  let charInput = e.key;
  if (charInput >= 'a' && charInput <= 'z') {
    // lowercase
    if (!e.ctrlKey && !e.metaKey && !e.altKey) {
      // no modifier key
      let newChar = charInput.toUpperCase();
      let start = el.selectionStart;
      let end = el.selectionEnd;
      el.value =
        el.value.substring(0, start) + newChar + el.value.substring(end);
      el.setSelectionRange(start + 1, start + 1);
      e.preventDefault();
    }
  }
};

export const forceUpperCase = (value, inputName, setFieldValue) => {
  if (inputName === 'eventName') {
    const newValue = value.toUpperCase();
    setFieldValue(inputName, newValue);
  }
};

export function yyyymmddformatDate(inputDate) {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
}

export const getCountryProperty = (countriesarray, countriesid, key) => {
  if (!Array.isArray(countriesarray)) {
    return 'United Kingdom'; // Return the default value if countriesarray is not an array
  }

  const country = countriesarray.find(
    (country) => country.CountryId === countriesid
  );
  return country ? country[key] : 'United Kingdom';
};

export function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return String(n)
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function redirectToPage(url) {
  // Regular expression for a simple URL validation
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // Check if the provided argument is a string and matches the URL pattern
  if (typeof url === 'string' && urlRegex.test(url)) {
    window.location.href = url;
  } else {
    console.error('Invalid URL:', url);
    // Optionally, you can throw an error, show a message, or handle the invalid URL in another way
  }
}

export function getCookie(a, b, c) {
  b = '; ' + document.cookie;
  c = b.split('; ' + a + '=');
  return !!(c.length - 1) ? c.pop().split(';').shift() : '';
}

export function convertTextToHyphenated(inputText) {
  const lowerCaseText = _.toLower(inputText);
  const hyphenatedText = _.replace(lowerCaseText, /[^a-zA-Z0-9]+/g, '-');
  return hyphenatedText;
}

export function isUUID(uuidString) {
  const schema = yup.string().uuid();

  // Validate the UUID string
  try {
    schema.validateSync(uuidString, { abortEarly: false });
    return true; // Validation successful, UUID is valid
  } catch (error) {
    return false; // Validation failed, UUID is not valid
  }
}

export function cleanSpaces(jsonObject) {
  const cleanedObject = _.mapValues(jsonObject, (value, key) => {
    return typeof value === 'string'
      ? value.trim().replace(/\s+/g, ' ')
      : value;
  });

  return cleanedObject;
}

export function formatDatetimeWithUTC(datetime) {
  const utcDate = new Date(datetime);
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12: false,
    timeZone: 'UTC',
  }).format(utcDate);
}

export function isNumeric(value) {
  return !_.isNaN(Number(value));
}

export function isNonEmptyString(value) {
  return !isNumeric(value) && _.isString(value) && !_.isEmpty(value);
}

export function capitalizeKeys(obj) {
  return _.mapKeys(obj, (value, key) => _.upperFirst(key));
}

export const convertToBoolean = (input) => {
  const trueTexts = ['true', 'yes', 'y'];
  const falseTexts = ['false', 'no', 'n'];

  // Handle different input types
  if (_.isBoolean(input)) {
    return input;
  }

  if (_.isString(input)) {
    const text = input.toLowerCase();
    if (trueTexts.includes(text)) {
      return true;
    } else if (falseTexts.includes(text)) {
      return false;
    }
  }

  if (_.isNumber(input)) {
    return input !== 0;
  }

  // For other types (arrays, objects, etc.), return false
  if (_.isObject(input) || _.isArray(input)) {
    return false;
  }

  // Default case: return false
  return false;
};

export function fromentTiktokcontents(Product, cartProduct) {
  return _.map(cartProduct, (item) => {
    const product = _.find(Product, { ProductId: item.productId });
    return {
      content_id: item.productId,
      content_name: product ? product.ProductTitle : '',
      quantity: item.quantity,
      price: item.totalItemPrice,
    };
  });
}

export const isJwtTimeExpired = (currentTimestamp) => {
  const currentTimeSeconds = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
  return currentTimeSeconds >= currentTimestamp;
};

export const getSortedProducts = (products) => {
  return _.orderBy(
    _.sortBy(products, ['ProductPrice', 'ProductType.ProductTypeShortcode']),
    [
      (product) =>
        product?.IsSoldOut ||
        calculateQuantityAvailable(
          product?.Quantity,
          product?.LockedTicket,
          product?.transactionItems_aggregate_TransactionStatus_Quantity
        ) === 0,
    ],
    ['asc']
  );
};
export const getItemTransferTickets = (id, item) => {
  return {
    ..._.defaults(
      _.pick(item, [
        'id',
        'productTitle',
        'seatDescription',
        'eventTicketAllocationId',
        'transactionItemId',
        'transferredTicketsDescription',
        'transferredTicketsShortCode',
        'transferredTicketsId',
        'section',
        'row',
        'seat',
      ]),
      {
        id: 0,
        productTitle: 'N/A',
        seatDescription: 'N/A',
        eventTicketAllocationId: 0,
        transactionItemId: 0,
        transferredTicketsDescription: 'N/A',
        transferredTicketsShortCode: 'N/A',
        transferredTicketsId: 0,
        section: null,
        row: null,
        seat: null,
      }
    ),
  };
};

export const getFullAddress = (
  VenueTitle,
  venueDescription,
  AddressLine1,
  addressLine2,
  city,
  postCode,
  countryDescription
) => {
  // Helper function to check if a value is not null, undefined, empty, or a blank string
  const isValid = (value) => !_.isNil(value) && !_.isEmpty(value.trim());

  // Split VenueTitle only once to retrieve last element
  const venueParts = VenueTitle?.trim().split(',') || [];
  const lastPart = venueParts.slice(-1)[0]?.trim();

  // Determine country based on last part of VenueTitle or fallback to countryDescription
  const country = lastPart?.length === 2 ? lastPart : countryDescription;

  // Handle venueDescription: use first part of it if it's comma-separated, else use the full description
  const mainTitle = isValid(venueDescription)
    ? venueDescription?.trim().split(',').length > 1
      ? venueDescription?.trim().split(',')[0]
      : venueDescription
    : venueParts[0];

  // Remove AddressLine1 if it matches mainTitle
  const _AddressLine1 =
    isValid(AddressLine1) && AddressLine1 !== mainTitle ? AddressLine1 : null;

  // Create an array of address components, filtering out invalid ones
  const addressComponents = [
    mainTitle,
    _AddressLine1,
    isValid(addressLine2) ? addressLine2 : null,
    isValid(city) ? city : null,
    isValid(postCode) ? postCode : null,
    isValid(country) ? country : null,
  ].filter(isValid);

  // Return VenueTitle if no valid address components are available, otherwise join them
  return addressComponents.length > 0
    ? addressComponents.join(', ')
    : VenueTitle;
};
