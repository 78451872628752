import * as React from 'react';
import styled from '@emotion/styled';
import { Button, Pane, EyeOpenIcon, EyeOffIcon } from 'evergreen-ui';

import FormInput from '../form-input/form-input';

const PasswordInputStyle = styled(Pane)`
  button {
    position: absolute;
    right: 4px;
    bottom: ${(props) => (props.validationmessage ? '30px' : '4px')};
  }
`;

const INPUT_TYPE = {
  input: 'input',
  password: 'password',
};

const FormPasswordInput = ({
  label,
  name,
  value,
  isInvalid,
  inputHeight,
  placeholder,
  validationMessage,
  onChange,
  ...rest
}) => {
  const [inputType, setInputType] = React.useState(INPUT_TYPE.password);

  function handleInputTypeChange() {
    if (inputType === INPUT_TYPE.input) {
      setInputType(INPUT_TYPE.password);
    } else {
      setInputType(INPUT_TYPE.input);
    }
  }

  return (
    <PasswordInputStyle
      position="relative"
      validationmessage={validationMessage}
    >
      <FormInput
        {...rest}
        label={label}
        name={name}
        type={inputType}
        value={value}
        isInvalid={isInvalid}
        inputHeight={inputHeight}
        placeholder={placeholder}
        validationMessage={validationMessage}
        onChange={onChange}
      />

      <Button
        type="button"
        intent="none"
        appearance="minimal"
        id="toggle-passowrd-field-type"
        onClick={handleInputTypeChange}
        data-testid="password-input-toggler"
      >
        {inputType === INPUT_TYPE.input ? (
          <EyeOffIcon color="inherit" fill="currentColor" />
        ) : (
          <EyeOpenIcon color="inherit" fill="currentColor" />
        )}
      </Button>
    </PasswordInputStyle>
  );
};

export default FormPasswordInput;
