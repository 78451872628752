const loadQueueClient = (callback) => {
  const scriptName = 'queueclient-min';
  const existingScript = document.getElementById(scriptName);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://static.queue-it.net/script/queueclient.min.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export default loadQueueClient;


