import React from 'react';
import { Helmet } from 'react-helmet';
import { titleCase, formatDatetime } from '../../helpers/functions';

const EventSEO = ({ data }) => {
  const { EventTitle, Venue, EventDateFrom, isHiddenVenue } = data;
  const titleCaseEventTitle = titleCase(EventTitle);
  const venueTitle = Venue?.VenueTitle;
  const hasEventDate = !!EventDateFrom;
  const eventDate = hasEventDate ? formatDatetime(EventDateFrom) : null;
  const eventInfo = `${EventTitle}${hasEventDate ? ` - ${eventDate}` : ''}`;
  const venueInfo = isHiddenVenue ? '' : ` at ${venueTitle}`;
  const punctuation = hasEventDate || !isHiddenVenue ? '.' : ' ';
  const descriptionText = isHiddenVenue
    ? 'Buy tickets now and discover more events at '
    : 'Buy tickets and discover more events at ';
  const description = `${eventInfo}${venueInfo}${punctuation}${descriptionText}TicketSir.com.`;

  return (
    <Helmet>
      <title>{titleCaseEventTitle}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default EventSEO;
