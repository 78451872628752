const clientStorage = {
  getItem: window.localStorage.getItem.bind(window.localStorage),
  setItem: window.localStorage.setItem.bind(window.localStorage),
  removeItem: window.localStorage.removeItem.bind(window.localStorage),
  setWithExpiry: function setWithExpiry(key, value, ttl) {
    const item = {
      value: value,
      expiry: new Date().getTime() + ttl,
    };

    this.setItem(key, JSON.stringify(item));
  },
  getWithExpiry: function getWithExpiry(key) {
    const itemString = this.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      this.removeItem(key);
      return null;
    }

    return item.value;
  },
};

export default clientStorage;
