import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { toaster, minorScale, majorScale, Text } from 'evergreen-ui';

import { useGoogleLogin } from '@react-oauth/google';
import Btn from '../../../components/btn/btn';
import { getErrorMessage } from '../../../helpers/functions';
import { UserIpAddressContext } from '../../../providers/user-ipaddress';
import { parseJwt, setAuthUser, setIsAuth } from '../../../helpers/auth';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google-icon.svg';
import apiManager from '../../../helpers/apiManager';

const GoogleAuth = ({
  callback,
  isSignup,
  followhost,
  isFollow,
  isPromoters,
  setIsShown,
  isPromotersUser,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useHistory();
  const { state: locationState } = useLocation();

  const { getLocationId } = React.useContext(UserIpAddressContext);

  async function handleGoogleAuth(response) {
    try {
      setIsLoading(true);

      if (response.access_token) {
        const { data } = await apiManager.GoogleAuth({
          access_token: response.access_token,
          isapplogin: false,
        });

        if (data) {
          const accessToken = data;
          const authUser = parseJwt(accessToken);

          if (accessToken) {
            apiManager.setToken(accessToken);
            setIsAuth(accessToken);
            setAuthUser(authUser);

            toaster.success(
              isSignup ? 'Account successfully created.' : 'Signin successful.'
            );
            if (callback) {
              callback();
              return;
            }

            if (authUser.country) {
              getLocationId(authUser.country);
            }

            if (locationState?.redirectTo) {
              history.push(locationState.redirectTo);
            } else if (isFollow) {
              followhost(authUser?.id);
            } else if (isPromoters) {
              setIsShown(true);
            } else if (isPromotersUser) {
              setIsShown(true);
            } else if (!authUser.addressLine1) {
              toaster.warning('Please fill your Address Details');
              history.push({
                pathname: '/account/profile',
                state: { openForm: true },
              });
            } else {
              history.push('/account/bookings');
            }
          }
        }
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGoogleError = (error) => {
    if (error) {
      toaster.danger(error.details);
    }
  };

  const googleAuth = useGoogleLogin({
    onSuccess: handleGoogleAuth,
    onError: handleGoogleError,
  });

  return (
    <>
      <Btn
        width="100%"
        minWidth="130px"
        fontSize="1em"
        type="button"
        look="tertiary-outlined"
        alignItems="center"
        justifyContent="center"
        height={majorScale(6)}
        columnGap={minorScale(2)}
        marginBottom={majorScale(3)}
        isLoading={isLoading}
        onClick={() => googleAuth()}
      >
        <GoogleIcon />
        <Text fontWeight="bold">Google</Text>
      </Btn>
    </>
  );
};

export default GoogleAuth;
