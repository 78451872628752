import jwt_decode from 'jwt-decode';
import { makeVar } from '@apollo/client';

import clientStorage from '../libs/client-storage';
import { apolloClient } from '../libs/apollo-client';
import {
  TICKETSIR_AUTH_USER,
  TICKETSIR_AUTH_USER_TOKEN,
  TS_AUTH_USER_REST_TOKEN,
} from '../constants';

// get initial values from the client storage (local or session)

const token = clientStorage.getItem(TICKETSIR_AUTH_USER_TOKEN);
const user = JSON.parse(clientStorage.getItem(TICKETSIR_AUTH_USER));

export const isAuthVar = makeVar(!!token);

export const authUserVar = makeVar(user || {});

export const tokenVar = makeVar(token);

export function parseJwt(token) {
  return jwt_decode(token);
}

export const handleLogout = () => {
  setAuthUser({});
  setIsAuth(false);
  apolloClient.resetStore();
  clientStorage.removeItem(TS_AUTH_USER_REST_TOKEN);
  authUserVar({});
};

export const setIsAuth = (token) => {
  if (token) {
    clientStorage.setItem(TICKETSIR_AUTH_USER_TOKEN, token);
    tokenVar(token);
    isAuthVar(true);
  } else {
    clientStorage.removeItem(TICKETSIR_AUTH_USER_TOKEN);
    isAuthVar(false);
  }
};

export const setAuthUser = (user) => {
  const isUserExist = Object.keys(user).length > 0;

  if (isUserExist) {
    clientStorage.setItem(TICKETSIR_AUTH_USER, JSON.stringify(user));
    authUserVar(user);
  } else {
    clientStorage.removeItem(TICKETSIR_AUTH_USER);
    authUserVar();
  }
};
