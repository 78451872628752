import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Button,
  toaster,
  minorScale,
} from 'evergreen-ui';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useMutation as ReactQueryMutation } from 'react-query';

import * as yup from 'yup';
import styled from '@emotion/styled';
import Btn from '../../btn/btn';
import useResponsiveness from '../../../hooks/use-responsiveness';
import Container from '../../container/container';
import { HTTP_REQ_STATUS } from '../../../helpers/enums';
import { getErrorMessage } from '../../../helpers/functions';
import FormInput from '../../form-input/form-input';
import apiManager from '../../../helpers/apiManager';

const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestTokenMutation($email: String!) {
    reset_password(email: $email) {
      message
    }
  }
`;

const FORGET_PASSWORD = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
});
const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  border-radius: 50%;

  top: 10px;
  font-size: 1em;

  border: none;

  &:hover {
    border: none !important;
  }
`;

const ForgetPassword = ({
  isForgetPassword,
  setForgetPassword,
  setISignin,
}) => {
  const { isLaptop } = useResponsiveness();

  // const [mutate] = useMutation(REQUEST_TOKEN_MUTATION);
  const { mutateAsync: resetYourPassword } = ReactQueryMutation(
    apiManager.ResetPassword
  );
  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);

  const handleRequestToken = async (values) => {
    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data, message } = await resetYourPassword(values);

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);

        toaster.success(message, {
          id: 'email-success',
          description:
            'Please check your email inbox for a password reset token.',
        });

        setForgetPassword(false);
        setISignin(true);
      }
    } catch (error) {
      toaster.danger(error);
      setStatus(HTTP_REQ_STATUS.error);
    }
  };

  return (
    <Dialog
      isShown={isForgetPassword}
      onCloseComplete={() => setForgetPassword(false)}
      hasFooter={false}
      // contentContainerProps={{ padding: 0 }}
      hasHeader={false}
      width="500px"
      shouldCloseOnOverlayClick={false}
      preventBodyScrolling={true}
    >
      <Formik
        validationSchema={FORGET_PASSWORD}
        initialValues={{ email: '' }}
        onSubmit={handleRequestToken}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <>
              <Pane
                maxHeight="27vh"
                borderTopLeftRadius="5px"
                width="100%"
                borderTopRightRadius="5px"
                alt=""
                maxWidth="100%"
                position="relative"
              />
              <CloseButton onClick={() => setForgetPassword(false)}>
                X
              </CloseButton>
              <Pane maxWidth={420} paddingY={minorScale(15)}>
                <Heading
                  fontSize="1.5rem"
                  textAlign="center"
                  fontWeight="900"
                  lineHeight="34px"
                  letterSpacing="0em"
                >
                  Reset Password
                </Heading>

                <form onSubmit={handleSubmit}>
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Email *"
                    inputMode="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                    validationMessage={touched.email && errors.email}
                    onChange={handleChange}
                  />

                  <Container maxWidth={isLaptop ? 300 : 'unset'}>
                    <Btn
                      type="submit"
                      width="100%"
                      marginY={majorScale(3)}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontSize="1em"
                      isLoading={status === HTTP_REQ_STATUS.loading}
                    >
                      Request Password Link
                    </Btn>
                  </Container>
                </form>
              </Pane>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ForgetPassword;
