import styled from '@emotion/styled';
import { Pane } from 'evergreen-ui';
import React from 'react';

const StyledLoadingStripe = styled(Pane)`
  @keyframes barWidth {
    0% {
      width: 0%;
      background: red;
    }
    25% {
      width: 50%;
      background: #f06d06;
    }
    50% {
      width: 100%;
      background: rgb(255, 255, 0);
    }
    75% {
      width: 50%;
      background: green;
    }
    100% {
      width: 0%;
      background: aqua;
    }
  }
  @keyframes barWidth2 {
    0% {
      width: 0%;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }

  height: 2px;
  width: 100%;
  .bar {
    position: relative;
    width: 0%;
    height: 100%;
    margin: 0 auto;
    animation: barWidth;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    &.bar1 {
      animation-delay: 0s;
      background: #02a09a;
      top: 0;
      z-index: 1;
    }
  }
`;
const LoadingStripe = () => {
  return (
    <StyledLoadingStripe>
      <div className="bar1 bar"></div>
    </StyledLoadingStripe>
  );
};

export default LoadingStripe;
