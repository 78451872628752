import {
  Text,
  Menu,
  Avatar,
  Popover,
  Position,
  ListItem,
  UnorderedList,
  minorScale,
  majorScale,
} from 'evergreen-ui';
import * as React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import MobileAuthHeader from './mobile-auth-header/mobile-auth-header';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { useReactiveVar } from '@apollo/client';
import { authUserVar, handleLogout, isAuthVar } from '../../helpers/auth';
import useResponsiveness from '../../hooks/use-responsiveness';
import { AUTH_VIEW } from '../../helpers/enums';
import { WithSubdomainLink } from '../../hooks/use-subdomain';

export const getProtectedRoutes = (authUser, pathname) => {
  const PROTECTED_ROUTES = [
    {
      title: 'Personal Profile',
      path: '/account/profile',
      // domainOnly: true,
    },
    {
      title: 'My tickets',
      path: '/account/bookings',
      // domainOnly: true,
    },
    {
      title: 'Wishlist',
      path: '/wishlist',
      // domainOnly: true,
    },
    {
      title: 'Followed Hosts',
      path: '/follow-host',
    },
    {
      title: 'My Messages',
      path: '/my-messages',
      hide: true,
    },
    ...(authUser.hostId && authUser.status === 'ACTIVE'
      ? [{ title: 'Host Profile', path: '/host/dashboard' }]
      : []),
  ];

  return PROTECTED_ROUTES;
};

function AuthUserAvatar({ name }) {
  const authUser = useReactiveVar(authUserVar);
  const { pathname } = useLocation();

  const [isRoutesOpen, setIsRoutesOpen] = React.useState(false);

  return (
    <Popover
      isShown={isRoutesOpen}
      position={Position.BOTTOM_LEFT}
      content={
        <Menu borderRadius={0} z-index={9999}>
          <UnorderedList>
            {getProtectedRoutes(authUser, pathname)
              .filter((protectedRoute) => !protectedRoute.hide)
              .map((protectedRoute, i) => {
                if (protectedRoute.domainOnly) {
                  return (
                    <ListItem key={i} color="var(--text-default-color)">
                      <WithSubdomainLink
                        exact
                        to={protectedRoute.path}
                        activeStyle={{ color: '#000' }}
                        role="menuitem"
                        style={{
                          display: 'block',
                          padding: '4px 8px',
                          color: 'inherit',
                        }}
                      >
                        {protectedRoute.title}
                      </WithSubdomainLink>
                    </ListItem>
                  );
                }
                return (
                  <ListItem key={i} color="var(--text-default-color)">
                    <NavLink
                      exact
                      to={protectedRoute.path}
                      activeStyle={{ color: '#000' }}
                      role="menuitem"
                      style={{
                        display: 'block',
                        padding: '4px 8px',
                        color: 'inherit',
                      }}
                    >
                      {protectedRoute.title}
                    </NavLink>
                  </ListItem>
                );
              })}

            <ListItem
              role="menuitem"
              paddingY={minorScale(1)}
              paddingX={minorScale(2)}
              color="var(--text-default-color)"
              fontSize="1em"
              cursor="pointer"
              onClick={handleLogout}
            >
              Sign Out
            </ListItem>
          </UnorderedList>
        </Menu>
      }
      onCloseComplete={() => setIsRoutesOpen(false)}
    >
      <ListItem>
        <Text
          display="flex"
          alignItems="center"
          color="#fff"
          cursor="pointer"
          onClick={() => setIsRoutesOpen((prev) => !prev)}
        >
          <Avatar
            size={minorScale(8)}
            appearance="solid"
            color="blue"
            name={name}
            fontSize="1em"
            className="mr-sm-2"
          />

          <ArrowDownIcon />
        </Text>
      </ListItem>
    </Popover>
  );
}

function AuthHeader() {
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);

  const authUserFullName = `${authUser?.firstName} ${authUser?.lastName}`;

  const { isLaptop } = useResponsiveness();

  return (
    <>
      {isLaptop ? (
        <UnorderedList display="flex" alignItems="center">
          {isAuth ? (
            <AuthUserAvatar name={authUserFullName} />
          ) : (
            Object.values(AUTH_VIEW).map((authView, i) => {
              return (
                !authView.hide && (
                  <ListItem
                    key={i}
                    fontSize="inherit"
                    marginRight={majorScale(5)}
                    color="#fff"
                  >
                    <NavLink
                      cursor="pointer"
                      to={authView.path}
                      role="menuitem"
                      activeStyle={{ borderBottom: '1px solid #fff' }}
                      style={{
                        display: 'inline-block',
                        padding: '8px 0',
                        color: '#fff',
                      }}
                    >
                      {authView.label}
                    </NavLink>
                  </ListItem>
                )
              );
            })
          )}
        </UnorderedList>
      ) : (
        <>
          <MobileAuthHeader />
        </>
      )}
    </>
  );
}

export default AuthHeader;
