import * as React from 'react';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import {
  Pane,
  Heading,
  Paragraph,
  toaster,
  minorScale,
  majorScale,
} from 'evergreen-ui';

import {
  setIsAuth,
  isAuthVar,
  authUserVar,
  setAuthUser,
} from '../../../helpers/auth';
import Btn from '../../../components/btn/btn';
import AppleAuth from '../apple-auth/apple-auth';
import GoogleAuth from '../google-auth/google-auth';
import FacebookAuth from '../facebook-auth/facebook-auth';
import Container from '../../../components/container/container';
import FormInput from '../../../components/form-input/form-input';
import { parseJwt, cleanSpaces } from '../../../helpers/functions';
import { VIEWPORT_BREAKPOINTS, AUTH_VIEW } from '../../../helpers/enums';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import FormPasswordInput from '../../../components/form-password-input/form-password-input';
import ticketSirLogo from '../../../assets/images/ticketsir-logo-lg.png';
import useSubdomain from '../../../hooks/use-subdomain';
import apiManager from '../../../helpers/apiManager';
import { useAppContext } from '../../../providers/cookie-policy';

export const SIGNIN_FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  password: yup.string().required('Password is required'),
});

const StyledModal = styled(Pane)`
  margin-right: -30px !important;
  margin-left: -30px !important;
`;

const StyledHeading = styled(Heading)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    font-size: 1.75rem;
  }
`;

const StyledHeadingContainer = styled(Container)`
  padding-bottom: 1rem;

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    max-width: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const StyledFormContainer = styled(Container)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    padding: 120px 0;
  }
`;

const Signin = ({ callback, closeModal, setCurrentAuthView }) => {
  const { setGuestLogin } = useAppContext();
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);
  const { hostname } = useSubdomain();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (callback && isAuth && authUser.id) {
      callback();
      closeModal();
    }
  }, [isAuth, authUser, callback, closeModal]);

  async function handleSignin(values) {
    try {
      setIsLoading(true);

      const { data: apiData } = await apiManager.VerifyUser({
        ...values,
        isAppuser: false,
      });

      if (apiData) {
        apiManager.setToken(apiData.jWT);
        const accessToken = apiData.jWT;

        const {
          iat,

          loginAttempts,
          ...authUser
        } = parseJwt(accessToken);

        // setGuestLogin(loginAttempts);

        setIsAuth(accessToken);
        setAuthUser(authUser);

        toaster.success('Signin successful.', { id: 'signin-success' });
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  }

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <StyledModal className="row" position="relative">
      <Pane
        className="col-md-5"
        backgroundColor={!isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
      >
        <StyledHeadingContainer maxWidth={400} paddingY={majorScale(3)}>
          <Pane
            width={isLaptop ? 180 : 130}
            overflow="hidden"
            marginBottom={majorScale(5)}
          >
            <img src={ticketSirLogo} alt="Logo" style={{ maxWidth: '100%' }} />
          </Pane>

          <StyledHeading
            marginBottom={minorScale(5)}
            fontWeight={900}
            fontSize="2rem"
          >
            Welcome Back!
          </StyledHeading>

          {isLaptop && (
            <>
              <SignupOption setCurrentAuthView={setCurrentAuthView} />
            </>
          )}
        </StyledHeadingContainer>
      </Pane>

      <Pane
        className="col"
        backgroundColor={isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
      >
        <StyledFormContainer maxWidth={420} paddingY={minorScale(15)}>
          {!hostname && (
            <Pane
              marginY={majorScale(2)}
              className="row w-100"
              justifyContent="space-between"
            >
              <Pane className="col-12 col-md-6">
                <GoogleAuth callback={closeModal} />
              </Pane>
              {/* hide facebook */}
              <Pane className="col-12 col-md-4" display="none">
                <FacebookAuth callback={closeModal} />
              </Pane>
              <Pane className="col-12 col-md-6">
                <AppleAuth callback={closeModal} />
              </Pane>
            </Pane>
          )}

          <Formik
            validationSchema={SIGNIN_FORM_SCHEMA}
            initialValues={{ email: '', password: '' }}
            onSubmit={(values) => handleSignin(cleanSpaces(values))}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Email"
                    inputMode="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                    validationMessage={touched.email && errors.email}
                    onChange={handleChange}
                  />

                  <FormPasswordInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                    validationMessage={touched.password && errors.password}
                    onChange={handleChange}
                  />

                  <Paragraph marginBottom={majorScale(2)} textAlign="right">
                    <Btn
                      type="button"
                      fontSize="1em"
                      look="primary-minimal"
                      paddingX={0}
                      onClick={() =>
                        setCurrentAuthView(AUTH_VIEW.FORGOT_PASSWORD.value)
                      }
                    >
                      Reset Password
                    </Btn>
                  </Paragraph>

                  <Container maxWidth={isLaptop ? 300 : 'unset'}>
                    <Btn
                      type="submit"
                      width="100%"
                      marginY={majorScale(3)}
                      fontSize="1em"
                      isLoading={isLoading}
                    >
                      Sign in
                    </Btn>
                  </Container>
                </form>
              );
            }}
          </Formik>
        </StyledFormContainer>
      </Pane>

      {!isLaptop && (
        <Pane
          className="col-md-5"
          textAlign="center"
          paddingTop={minorScale(5)}
          paddingBottom={minorScale(9)}
          backgroundColor="rgba(207, 216, 220, 0.15)"
        >
          <SignupOption setCurrentAuthView={setCurrentAuthView} />
        </Pane>
      )}

      <CloseIcon
        onClick={() => {
          closeModal();
        }}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '2rem',
          top: '2.5rem',
        }}
      />
    </StyledModal>
  );
};

export default Signin;

export const SignupOption = ({ setCurrentAuthView }) => {
  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });
  return (
    <>
      <Paragraph
        marginBottom={minorScale(5)}
        color="var(--text-default-color)"
        fontSize="1rem"
      >
        Don't have an account?
      </Paragraph>

      <Pane
        display={isLaptop ? 'block' : 'flex'}
        justifyContent={isLaptop ? 'unset' : 'center'}
      >
        <Btn
          fontSize="1em"
          type="button"
          look="secondary-filled"
          columnGap={minorScale(3)}
          onClick={() => setCurrentAuthView(AUTH_VIEW.SIGNUP.value)}
        >
          Sign up
          <ArrowRightIcon />
        </Btn>
      </Pane>
    </>
  );
};
