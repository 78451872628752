import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function PhotoLightbox({
  photos,
  initialPhotoIndex,
  isLightboxOpen,
  onCloseComplete,
}) {
  const [photoIndex, setPhotoIndex] = React.useState(initialPhotoIndex);

  React.useEffect(() => {
    setPhotoIndex(initialPhotoIndex);
  }, [initialPhotoIndex]);

  if (isLightboxOpen) {
    return (
      <Lightbox
        mainSrc={photos[photoIndex]}
        nextSrc={photos[(photoIndex + 1) % photos.length]}
        prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
        onCloseRequest={onCloseComplete}
        onMovePrevRequest={() =>
          setPhotoIndex((prev) => (prev + photos.length - 1) % photos.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((prev) => (prev + 1) % photos.length)
        }
      />
    );
  }

  return null;
}

export default PhotoLightbox;
