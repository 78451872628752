import * as yup from 'yup';
import * as React from 'react';
import { Formik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { gql, useMutation } from '@apollo/client';
import {
  Heading,
  majorScale,
  Pane,
  toaster,
  UnorderedList,
  TickCircleIcon,
  ListItem,
} from 'evergreen-ui';

import {
  HTTP_REQ_STATUS,
  AUTH_VIEW,
  VIEWPORT_BREAKPOINTS,
} from '../../../../helpers/enums';
import Btn from '../../../../components/btn/btn';
import { getErrorMessage } from '../../../../helpers/functions';
import { AuthViewContext } from '../../../../providers/auth-view';
import Container from '../../../../components/container/container';
import FormPasswordInput from '../../../../components/form-password-input/form-password-input';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $email: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    change_password(
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
      email: $email
    ) {
      message
    }
  }
`;

const RESET_PASSWORD_FORM_SCHEMA = yup.object().shape({
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must contain up to 8 characters')
    .matches(/[A-Z]/, 'New password must contain at least 1 uppercase letter')
    .matches(/[!@#$%^&*-?]/, 'New password must contain at least 1 symbol'),
  confirmNewPassword: yup
    .string()
    .required('Confirm new password is required')
    .test('confirmNewPassword', 'Passwords must match', (value, context) => {
      return context.parent.newPassword === value;
    }),
});

function ResetPassword({ email }) {
  const [mutate] = useMutation(RESET_PASSWORD_MUTATION);
  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);

  const { setCurrentAuthView } = React.useContext(AuthViewContext);

  async function handleResetPassword(values) {
    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data } = await mutate({
        variables: { ...values, email },
      });

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);

        // opens the sign-in dialog and form
        setCurrentAuthView(AUTH_VIEW.SIGNIN.value);

        toaster.success(data.change_password.message, {
          id: 'reset-password-success',
          description: 'Please use your new password to sign in.',
        });
      }
    } catch (error) {
      setStatus(HTTP_REQ_STATUS.error);
      getErrorMessage(error);
    }
  }

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <>
      <Heading
        marginBottom={majorScale(4)}
        color="#000"
        fontSize="1.5em"
        fontWeight={700}
        textAlign="center"
      >
        Reset Password
      </Heading>

      <Formik
        enableReinitialize
        validationSchema={RESET_PASSWORD_FORM_SCHEMA}
        initialValues={{ newPassword: '', confirmNewPassword: '' }}
        onSubmit={handleResetPassword}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <Pane is="form" onSubmit={handleSubmit}>
              <FormPasswordInput
                marginBottom={majorScale(2)}
                inputHeight={majorScale(5)}
                label="New Password"
                name="newPassword"
                placeholder="Enter new password"
                value={values.newPassword}
                isInvalid={touched.newPassword && !!errors.newPassword}
                validationMessage={touched.newPassword && errors.newPassword}
                onChange={handleChange}
              />

              <FormPasswordInput
                marginBottom={majorScale(2)}
                inputHeight={majorScale(5)}
                label="Confirm New Password"
                name="confirmNewPassword"
                placeholder="Enter new password again"
                value={values.confirmNewPassword}
                isInvalid={
                  touched.confirmNewPassword && !!errors.confirmNewPassword
                }
                validationMessage={
                  touched.confirmNewPassword && errors.confirmNewPassword
                }
                onChange={handleChange}
              />
              <Pane
                className="row"
                marginRight="0 !important"
                marginLeft="0 !important"
              >
                <UnorderedList className="col-12">
                  <ListItem
                    icon={<TickCircleIcon />}
                    iconColor={
                      values.newPassword && /[A-Z]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    color={
                      values.newPassword && /[A-Z]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    fontSize="1em"
                  >
                    Uppercase Character
                  </ListItem>

                  <ListItem
                    icon={<TickCircleIcon />}
                    iconColor={
                      values.newPassword && /[a-z]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    color={
                      values.newPassword && /[a-z]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    fontSize="1em"
                  >
                    Lowercase Character
                  </ListItem>

                  <ListItem
                    icon={<TickCircleIcon />}
                    iconColor={
                      values.newPassword && /[0-9]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    color={
                      values.newPassword && /[0-9]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    fontSize="1em"
                    marginBottom="0"
                  >
                    One Number
                  </ListItem>

                  <ListItem
                    icon={<TickCircleIcon />}
                    iconColor={
                      values.newPassword &&
                      /[^a-zA-Z0-9]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    color={
                      values.newPassword &&
                      /[^a-zA-Z0-9]/.test(values.newPassword)
                        ? 'success'
                        : 'muted'
                    }
                    fontSize="1em"
                  >
                    One Special Character
                  </ListItem>

                  <ListItem
                    icon={<TickCircleIcon />}
                    iconColor={
                      values.newPassword && values.newPassword.length >= 8
                        ? 'success'
                        : 'muted'
                    }
                    color={
                      values.newPassword && values.newPassword.length >= 8
                        ? 'success'
                        : 'muted'
                    }
                    fontSize="1em"
                  >
                    8 Characters Minimum
                  </ListItem>
                </UnorderedList>
              </Pane>

              <Container maxWidth={isLargeScreen ? 300 : 'unset'}>
                <Btn
                  type="submit"
                  width="100%"
                  marginY={majorScale(3)}
                  fontSize="1em"
                  isLoading={status === HTTP_REQ_STATUS.loading}
                >
                  Reset password
                </Btn>
              </Container>
            </Pane>
          );
        }}
      </Formik>
    </>
  );
}

export default ResetPassword;
