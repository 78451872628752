import { Endpoints } from './constants';
import { Instance } from './instance';

async function VerifySuperAffiliateUser(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.VerifySuperAffiliateUser, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function RegisterInterest(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.RegisterInterest, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function VerifyUser(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.VerifyUser, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetStripeIntent() {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetStripeIntent)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetCards() {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetCards)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function DeleteCard(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteCard, null, { params: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function LockTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.LockTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SubmitTransaction(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SubmitTransaction, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function VerifyPaystack(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.VerifyPaystack, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AppleSignedInUserUpdate(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AppleSignedInUserUpdate, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function transactionVerify(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.transactionVerify, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function JoinTheEventForPromotionAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.JoinTheEventForPromotionAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SetPromoterCommissionOfTheEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SetPromoterCommissionOfTheEvent, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ActiveDeactivePromoterAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ActiveDeactivePromoterAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ApproveRejectPromoterRequestAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ApproveRejectPromoterRequestAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function PromoteTheEventAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.PromoteTheEventAsync, null, { params: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function EventsForPromotionList(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.EventsForPromotionList, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function EventListingForHost(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.EventListingForHost, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function StopPromotingTheEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.StopPromotingTheEvent, null, { params: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function StarPromotingTheEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.StarPromotingTheEvent, null, { params: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function PromoterOverview(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.PromoterOverview, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ExistingPromotersEntryForNewEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExistingPromotersEntryForNewEvent, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateInvoiceBankAccount(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateInvoiceBankAccount, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FollowTheHostAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FollowTheHostAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetUserNotification(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetUserNotification, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AcknowledgeNotification(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AcknowledgeNotification, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function OnOffNotifications(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.OnOffNotifications, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetFollowedHostList(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetFollowedHostList, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FollowedHostListAction(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FollowedHostListAction, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function InviteToPromoteTheEventAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.InviteToPromoteTheEventAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetUserNotificationFrequencyTypes(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetUserNotificationFrequencyTypes, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SaveSalesEmailNotificationPreference(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SaveSalesEmailNotificationPreference, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function PromoterRequestStatus(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.PromoterRequestStatus, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ExportTicketALLOCATION = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportTicketALLOCATION, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function DeleteEventProduct(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteEventProduct, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SaveEventBasicDetails(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SaveEventBasicDetails, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function addEventlocation(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.addEventlocation, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventGeneral(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventGeneral, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventDate(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventDate, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventSummary(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventSummary, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ResetPassword(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ResetPassword, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ChangeOldPassword(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ChangeOldPassword, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeleteUserEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteUserEvent, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function InsertUserEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.InsertUserEvent, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateUserProfile(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateUserProfile, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddHost(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddHost, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddBank(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddBank, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeleteUserBank(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteUserBank, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function InitiateEmailVerification(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.InitiateEmailVerification, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function VerifyResetPasswordToken(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.VerifyResetPasswordToken, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UserRegistration(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UserRegistration, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function VerifyEmail(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.VerifyEmail, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GoogleAuth(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GoogleAuth, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AppleAuth(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AppleAuth, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetFurpBankProviders(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetFurpBankProviders, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FurpGetBank(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FurpGetBank, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FurpGetBeneficiaryRequirement(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FurpGetBeneficiaryRequirement, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddThirdPartyEventUserAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddThirdPartyEventUserAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetSiteMaintenanceStatus(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetSiteMaintenanceStatus, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetAvailableSeats(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetAvailableSeats, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ConfirmChangeSeats(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ConfirmChangeSeats, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeteleEventDressTerm(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeteleEventDressTerm, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ReplyEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ReplyEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function TransferTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TransferTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetTicketToTransfer(paramData, data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetTicketToTransfer, data, {
      params: paramData,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function TicketToRecallReject(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TicketToRecallReject, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AcceptTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AcceptTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UploadImageToS3(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UploadImageToS3, data, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  VerifyUser,
  GetStripeIntent,
  VerifySuperAffiliateUser,
  RegisterInterest,
  GetCards,
  DeleteCard,
  LockTickets,
  SubmitTransaction,
  VerifyPaystack,
  AddEnquiry,
  AppleSignedInUserUpdate,
  transactionVerify,
  JoinTheEventForPromotionAsync,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  PromoteTheEventAsync,
  EventsForPromotionList,
  EventListingForHost,
  StopPromotingTheEvent,
  StarPromotingTheEvent,
  PromoterOverview,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  FollowTheHostAsync,
  GetUserNotification,
  AcknowledgeNotification,
  OnOffNotifications,
  GetFollowedHostList,
  FollowedHostListAction,
  InviteToPromoteTheEventAsync,
  GetUserNotificationFrequencyTypes,
  SaveSalesEmailNotificationPreference,
  PromoterRequestStatus,
  ExportTicketALLOCATION,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventGeneral,
  AddEventDate,
  AddEventTickets,
  AddEventSummary,
  ResetPassword,
  ChangeOldPassword,
  DeleteUserEvent,
  UpdateUserProfile,
  InsertUserEvent,
  AddHost,
  AddBank,
  DeleteUserBank,
  InitiateEmailVerification,
  VerifyResetPasswordToken,
  UserRegistration,
  VerifyEmail,
  GoogleAuth,
  AppleAuth,
  GetFurpBankProviders,
  FurpGetBank,
  FurpGetBeneficiaryRequirement,
  AddThirdPartyEventUserAsync,
  GetSiteMaintenanceStatus,
  GetAvailableSeats,
  ConfirmChangeSeats,
  DeteleEventDressTerm,
  UpdateEnquiry,
  ReplyEnquiry,
  TransferTickets,
  GetTicketToTransfer,
  TicketToRecallReject,
  AcceptTickets,
  UploadImageToS3
};
