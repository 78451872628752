import { Pane } from 'evergreen-ui';
import styled from '@emotion/styled';

const Container = styled(Pane)`
  width: 100%;
  margin: 0 auto;
  padding-right: 50px;
  padding-left: 50px;

  /* Default padding values for screens wider than 720px */

  @media (max-width: 720px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  /* Additional styles can be added for other screen sizes here */

  /* overflow: auto; */
  /* &::-webkit-scrollbar {
    display: none;
  } */
`;

export default Container;
