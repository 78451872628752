const loaderqueueconfigloader = (callback) => {
  const scriptName = 'queueit-loader-script';
  const existingScript = document.getElementById(scriptName);
  
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://static.queue-it.net/script/queueconfigloader.min.js';
    script.type = 'text/javascript';

    script.setAttribute(
      'data-queueit-c',
      `${process.env.REACT_APP_QUEUEIT_CUSTOMERID}`
    );
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export default loaderqueueconfigloader;
