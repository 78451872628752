import React from 'react';
import { Pane, majorScale, Pill } from 'evergreen-ui';
import { gql, useQuery } from '@apollo/client';
import { clamp } from 'lodash';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import NotificationIcon from '../../assets/icons/notification.png';
import { isAuthVar, authUserVar, tokenVar } from '../../helpers/auth';
import useResponsiveness from '../../hooks/use-responsiveness';

export const NOTIFICATION_BELL_COUNT = gql`
  query NotificationsBall($UserId: String) {
    notificationCount(userId: $UserId)
  }
`;

const Notification = () => {
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);
  const { isLaptop } = useResponsiveness();

  const { error, data, loading, refetch } = useQuery(NOTIFICATION_BELL_COUNT, {
    skip: !isAuth,
    fetchPolicy: 'network-only',
    variables: {
      UserId: authUser?.id,
    },
  });

  return (
    <>
      {isAuth ? (
        <Pane marginRight={isLaptop && majorScale(5)} position="relative">
          <Link to="/notfication" exact="true">
            <img
              src={NotificationIcon}
              alt="notification"
              style={{ verticalAlign: 'middle', maxHeight: '60px' }}
            />
          </Link>
          {data?.notificationCount > 0 && (
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin={8}
              color="#ffff"
              position="absolute"
              top="-13px"
              left="7px"
              width="18px"
              height="18px"
              background="#D61B1B"
              textAlign="center"
              fontSize="10px"
              style={{ borderRadius: '50%' }}
            >
              {clamp(data?.notificationCount, 1, 99) === 99
                ? '99+'
                : data?.notificationCount}
            </Pane>
          )}
        </Pane>
      ) : (
        ''
      )}
    </>
  );
};

export default Notification;
