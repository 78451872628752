import React from 'react';
import { Pane, toaster } from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import { useMutation } from 'react-query';
import { authUserVar, setAuthUser } from '../../helpers/auth';
import {
  isfollowAppleUser,
  setIsfollowAppleUser,
} from '../../features/auth/apple-auth/apple-auth';
import { apolloClient } from '../../libs/apollo-client';
import AppleSigninPersonalInfoForm from './apple-signin-personal-info-form/apple-signin-personal-info-form';
import apiManager from '../../helpers/apiManager';
import { NOTIFICATION_TYPE } from '../../helpers/enums';
import { EVENT_DETAILS_QUERY } from '../../pages/event-details/event-details';
import { USER_PROFILE_QUERY } from '../../pages/account/profile/profile';

const AppleSigninPersonalInfo = () => {
  const authUser = useReactiveVar(authUserVar);
  const followHostApple = useReactiveVar(isfollowAppleUser);
  const [loader, setLoader] = React.useState(false);

  const { mutateAsync: appleUserUpdateMutation } = useMutation(
    apiManager.AppleSignedInUserUpdate
  );

  const { mutateAsync: followedHost } = useMutation(
    apiManager.FollowTheHostAsync
  );

  const handleRefetch = async () => {
    await apolloClient.refetchQueries({
      include: [EVENT_DETAILS_QUERY],
    });
  };

  const handleRefetchProfile = async () => {
    await apolloClient.refetchQueries({
      include: [USER_PROFILE_QUERY],
    });
  };

  const followhost = async () => {
    try {
      setLoader(true);

      const { data, message } = await followedHost({
        followedByUserId: authUser?.id,
        hostId: followHostApple?.CompanyId,
        notificationTypeId: NOTIFICATION_TYPE?.EVENTTICKETSALE,
        isFollowing: true,
      });

      if (data) {
        toaster.success(
          message || 'Thanks for showing interest to promote the events'
        );
        handleRefetch();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  const handleAppleUserUpdate = async (values) => {
    try {
      setLoader(true);
      const { data, message } = await appleUserUpdateMutation({
        ...values,
        userId: authUser.id,
      });

      if (data) {
        setLoader(false);
        const { firstName, lastName, emailAddress } = data;
        toaster.success(message);
        setAuthUser({
          ...authUser,
          firstName,
          lastName,
          emailAddress,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.emailAddress,
          EmailAddress: data?.emailAddress,
          appleAuthUser: false,
        });
        handleRefetchProfile();
        if (followHostApple) {
          followhost();
          setIsfollowAppleUser({});
        }
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  return (
    <Pane>
      <AppleSigninPersonalInfoForm
        handleAppleUserUpdate={handleAppleUserUpdate}
        loader={loader}
      />
    </Pane>
  );
};

export default AppleSigninPersonalInfo;
