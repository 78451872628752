import React from 'react';
import { Link } from 'react-router-dom';
import { Pane, Paragraph, Heading, majorScale, minorScale } from 'evergreen-ui';

import {
  EventBanner,
  EventBannerWithContainer,
} from '../event-banner/event-banner';
import Btn from '../btn/btn';
import Header from '../header/header';
import Footer from '../footer/footer';
import Container from '../container/container';
import { formatDatetime } from '../../helpers/functions';
import useResponsiveness from '../../hooks/use-responsiveness';
import expiredEventImage from '../../assets/images/expired-event.png';
import { ReactComponent as DateIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as VenueIcon } from '../../assets/icons/location-marker.svg';

const EventExpired = ({ eventInfo }) => {
  return (
    <>
      <EventExpiredBanner eventInfo={eventInfo} />

      <Pane>
        <Container maxWidth="100%" paddingY={majorScale(1)}>
          <Pane
            minHeight="70vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingY={majorScale(5)}
            textAlign="center"
          >
            <Heading is="h1" color="inherit" fontSize="1.85em" fontWeight={900}>
              Expired Page
            </Heading>

            <Paragraph
              marginY={minorScale(7)}
              color="inherit"
              fontSize="1.225em"
            >
              The event you are trying to access is no longer available.
            </Paragraph>

            <Pane>
              <Link to="/search-events">
                <Btn
                  fontSize="1em"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  Browse Events
                </Btn>
              </Link>
            </Pane>

            <Pane width="320px" maxWidth="80%" marginY={majorScale(5)}>
              <img src={expiredEventImage} alt="Expired Event" width="100%" />
            </Pane>
          </Pane>
        </Container>
      </Pane>

      <Pane>
        <Footer />
      </Pane>
    </>
  );
};

const EventExpiredBanner = ({ eventInfo }) => {
  const { isLaptop } = useResponsiveness();

  if (!eventInfo) {
    return (
      <Pane backgroundColor="var(--primary-default-color)">
        <Container maxWidth="100%" paddingY={majorScale(1)}>
          <Header />
        </Container>
      </Pane>
    );
  }

  const { EventTitle, VenueTitle, coverImageUrl, EventDates } = eventInfo;

  return (
    <>
      {isLaptop ? (
        <>
          <Pane backgroundColor="var(--primary-default-color)">
            <Container maxWidth="100%" paddingY={majorScale(1)}>
              <Header />
            </Container>
          </Pane>
          <EventBannerWithContainer image={coverImageUrl}>
            <Pane
              paddingX={minorScale(5)}
              paddingY={majorScale(8)}
              height="inherit"
              display="flex"
              flexDirection="column"
              color="#fff"
              fontSize="1rem"
            >
              <Heading
                is="h1"
                marginTop="auto"
                marginBottom={minorScale(4)}
                color="inherit"
                fontSize="1.85em"
                fontWeight={900}
              >
                {EventTitle}
              </Heading>

              <Paragraph
                marginBottom={minorScale(3)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
              >
                <DateIcon className="mr-3" />
                {formatDatetime(EventDates[0]?.EventStartDate)}
              </Paragraph>

              <Paragraph
                marginBottom={minorScale(3)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
              >
                <VenueIcon className="mr-3" />
                {VenueTitle}
              </Paragraph>
            </Pane>
          </EventBannerWithContainer>
        </>
      ) : (
        <>
          <Pane backgroundColor="var(--primary-default-color)">
            <Container paddingTop={minorScale(3)} paddingBottom={minorScale(5)}>
              <Header />
            </Container>
          </Pane>
          <EventBanner image={coverImageUrl} />
          <Pane
            paddingX={minorScale(5)}
            paddingY={majorScale(2)}
            display="flex"
            flexDirection="column"
            fontSize="1rem"
          >
            <Heading
              is="h1"
              marginTop="auto"
              marginBottom={minorScale(4)}
              color="inherit"
              fontSize="1.5rem"
              fontWeight={900}
            >
              {EventTitle}
            </Heading>

            <Paragraph
              marginBottom={minorScale(3)}
              display="flex"
              alignItems="center"
              color="inherit"
              fontSize="1.225em"
              columnGap={minorScale(3)}
            >
              <DateIcon />
              {formatDatetime(EventDates[0]?.EventStartDate)}
            </Paragraph>

            <Paragraph
              marginBottom={minorScale(3)}
              display="flex"
              alignItems="center"
              color="inherit"
              fontSize="1.225em"
              columnGap={minorScale(3)}
            >
              <VenueIcon width={'32px'} />
              {VenueTitle}
            </Paragraph>
          </Pane>
        </>
      )}
    </>
  );
};
export default EventExpired;
