import React, { createContext, useContext, useState } from 'react';
import clientStorage from '../libs/client-storage';
import { TS_ACCEPT_COOKIES } from '../constants';
import { isAuthVar } from '../helpers/auth';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const CookieProvider = ({ children }) => {
  const isAuth = isAuthVar();
  const [isCookiesAccepted, setIsCookiesAccepted] = React.useState(false);
  const [isGuestLogin, setGuestLogin] = React.useState(false);

  const handleAcceptCookies = () => {
    clientStorage.setItem(TS_ACCEPT_COOKIES, true);
    setIsCookiesAccepted(true);
  };

  const handleGuestCheckout = () => {
    setGuestLogin(false);
  };

  React.useEffect(() => {
    if (!!clientStorage.getItem(TS_ACCEPT_COOKIES)) {
      setIsCookiesAccepted(true);
    } else {
      setIsCookiesAccepted(false);
    }
  }, [isAuth]);

  return (
    <AppContext.Provider
      value={{
        isCookiesAccepted,
        handleAcceptCookies,
        setIsCookiesAccepted,
        isGuestLogin,
        setGuestLogin,
        handleGuestCheckout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
