import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  toaster,
} from 'evergreen-ui';
import get from 'lodash/get';
import ReactPixel from 'react-facebook-pixel';
import { Formik } from 'formik';
import trim from 'lodash/trim';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import Btn from '../../../components/btn/btn';
import FormInput from '../../../components/form-input/form-input';
import apiManager from '../../../helpers/apiManager';

export const EXTERNAL_EMAIL_FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
});

const ExternalEmailDialog = ({
  currentEvent,
  closeActionDialog,
  externalTicketSellerLinks,
  EventId,
}) => {
  const { isLoading, mutateAsync: externalEvent } = useMutation(
    apiManager.AddThirdPartyEventUserAsync
  );

  return (
    <Dialog
      isShown={true}
      onCloseComplete={() => closeActionDialog()}
      hasFooter={false}
      contentContainerProps={{ padding: 0 }}
      hasHeader={false}
      width="784px"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      preventBodyScrolling={true}
    >
      <Pane padding={majorScale(5)}>
        <Pane
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginBottom={majorScale(2)}
        >
          <Paragraph
            cursor="pointer"
            fontSize="1.3em"
            onClick={() => closeActionDialog()}
          >
            X
          </Paragraph>
        </Pane>

        <Pane textAlign="center" marginBottom={majorScale(2)}>
          <Heading
            fontSize="1.5em"
            fontWeight="700"
            marginBottom={majorScale(2)}
          >
            Continue with your ticket purchase?
          </Heading>

          <Paragraph fontSize="1em">
            You will be redirected to a third-party site to complete your
            transaction. Kindly provide your email address to help us keep you
            updated about the event.
          </Paragraph>
        </Pane>

        <Formik
          validationSchema={EXTERNAL_EMAIL_FORM_SCHEMA}
          initialValues={{ email: '' }}
          onSubmit={(values) => {
            externalEvent(
              {
                eventId: EventId,
                emailAddress: trim(values.email),
              },
              {
                onSuccess: () => {
                  ReactPixel.fbq('track', 'Lead', {
                    content_name: 'External event',
                    value: 0,
                    currency: get(
                      currentEvent,
                      'CurrencyDetail.CurrencyShortcode',
                      'GBP'
                    ),
                  });
                  setTimeout(() => {
                    window.open(externalTicketSellerLinks, '_blank');
                    closeActionDialog();
                  }, 50);
                },
                onError: (error) => toaster.danger(error),
              }
            );
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            const isEmailValid = !touched.email || !errors.email;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  marginBottom={majorScale(5)}
                  inputHeight={majorScale(5)}
                  label="Email"
                  inputMode="email"
                  name="email"
                  placeholder="Enter email"
                  value={values.email}
                  isInvalid={touched.email && !!errors.email}
                  validationMessage={touched.email && errors.email}
                  onChange={handleChange}
                />

                <Pane display="flex" justifyContent="center">
                  <Btn
                    type="button"
                    fontSize="1em"
                    columnGap={majorScale(1)}
                    marginRight={majorScale(3)}
                    look="primary-outlined"
                    onClick={() => closeActionDialog()}
                    width="200px"
                    padding="20px"
                  >
                    Cancel
                  </Btn>

                  <Btn
                    type="submit"
                    fontSize="1em"
                    width="200px"
                    padding="20px"
                    disabled={!values.email || !isEmailValid}
                    isLoading={isLoading}
                  >
                    Continue
                  </Btn>
                </Pane>
              </form>
            );
          }}
        </Formik>
      </Pane>
    </Dialog>
  );
};

export default ExternalEmailDialog;
