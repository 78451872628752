import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  Button,
  Text,
  minorScale,
} from 'evergreen-ui';

import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Btn from '../btn/btn';
import useResponsiveness from '../../hooks/use-responsiveness';
import SignInModel from '../sign-in-model/sign-in-model';
import { VIEWPORT_BREAKPOINTS } from '../../helpers/enums';
import Container from '../container/container';
import SignupDialog from '../signup-dialog/signup-dialog';

const EventImageBlurredBanner = styled(Pane)`
  background-color: var(--secondary-dark-color);
  filter: blur(1rem);
  background: ${(props) =>
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${props.image}') no-repeat center`};
  background-size: 100% 100%;
  height: 233px;
  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    height: 172px;
  }
`;

const EventImageBanner = styled(Pane)`
  background-color: var(--secondary-dark-color);
  background: ${(props) =>
    `linear-gradient(to bottom, 
    rgba(217, 217, 217, 0) 16.51%, rgba(6, 48, 77, .8)), url('${props.image}') no-repeat center`};
  background-size: cover;
  height: 233px;
  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    height: 172px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 20px;
  border-radius: 50%;
  padding: 25px 18px;

  top: 15px;
  font-size: 1.5em;
  opacity: 0.7;
  border: none;

  &:hover {
    border: none !important;
  }
`;

const WithoutloginFollowHostModel = ({
  isShown,
  setIsShown,
  EventTitle,
  id,
  image,
  commission,
  CompanyId,
  isFollowHostIncluded,
  refetch,
  isFollow,
}) => {
  const { isLaptop } = useResponsiveness();
  const history = useHistory();
  const [isSignin, setISignin] = React.useState(false);

  const [isSignup, setIsSignUp] = React.useState(false);

  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        confirmLabel="Custom Label"
        hasFooter={false}
        contentContainerProps={{ padding: 0 }}
        hasHeader={false}
        width="784px"
        shouldCloseOnOverlayClick={false}
      >
        <Pane
          marginBottom={!isLaptop && majorScale(2)}
          width="100%"
          position="relative"
        >
          <EventImageBlurredBanner image={image} />
          <Pane
            width="100%"
            height="100%"
            position="absolute"
            top="0"
            display="flex"
            flexDirection="column"
          >
            <Container maxWidth={1000} height="100%" paddingTop={minorScale(2)}>
              <EventImageBanner image={image}></EventImageBanner>
            </Container>
          </Pane>
        </Pane>
        <CloseButton onClick={() => setIsShown(false)}>X</CloseButton>
        <Pane
          paddingX={isLaptop ? majorScale(15) : majorScale(1)}
          paddingY={isLaptop ? majorScale(6) : majorScale(2)}
        >
          {/* <Heading fontSize="2.5em" marginBottom={majorScale(2)}>
            Great!
          </Heading> */}
          <Pane textAlign="center">
            <Paragraph fontSize="1.12em" marginBottom={majorScale(2)}>
              Sign in or sign up for a free account to follow the host and
              receive emails and notifications about their upcoming events and
              when tickets go on sale. You'll never miss out on new experiences!
            </Paragraph>

            <Pane
              display="flex"
              justifyContent="center"
              marginBottom={majorScale(2)}
            >
              <Btn
                fontSize="1em"
                width="200px"
                onClick={() => {
                  setIsShown(false);
                  setISignin(true);
                }}
              >
                Sign in
              </Btn>
            </Pane>
            <Paragraph fontSize="1.12em">
              Don’t have an account?{' '}
              <Text
                fontSize="1em"
                color="var(--primary-default-color ) !important"
                cursor="pointer"
                onClick={() => {
                  setIsShown(false);
                  setIsSignUp(true);
                }}
              >
                Sign up
              </Text>
            </Paragraph>
          </Pane>
        </Pane>
      </Dialog>

      <SignInModel
        isSignin={isSignin}
        setISignin={setISignin}
        setIsShown={setIsShown}
        isPromoters={false}
        CompanyId={CompanyId}
        isFollowHostIncluded={isFollowHostIncluded}
        refetch={refetch}
        isFollow={isFollow}
      />

      {isSignup && (
        <SignupDialog
          isSignin={isSignup}
          setISignin={setIsSignUp}
          setIsShown={setIsShown}
          isPromoters={false}
          CompanyId={CompanyId}
          isFollowHostIncluded={isFollowHostIncluded}
          refetch={refetch}
          isFollow={true}

        />
      )}
    </Pane>
  );
};

export default WithoutloginFollowHostModel;
