import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from 'evergreen-ui';

const BTN_LOOK_PROPS = {
  'primary-filled': {
    color: '#fff',
    backgroundColor: 'var(--primary-default-color)',
    borderColor: 'var(--primary-default-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--primary-default-color)',
    hoverBorderColor: 'var(--primary-default-color)',
  },
  'primary-outlined': {
    color: 'var(--primary-default-color)',
    backgroundColor: 'transparent',
    borderColor: 'var(--primary-default-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--primary-default-color)',
    hoverBorderColor: 'var(--primary-default-color)',
  },
  'primary-minimal': {
    color: 'var(--primary-default-color)',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    hoverColor: 'var(--primary-light-color)',
    hoverBackgroundColor: 'transparent',
    hoverBorderColor: 'transparent',
  },
  'secondary-filled': {
    color: 'var(--secondary-default-color)',
    backgroundColor: 'var(--secondary-light-color)',
    borderColor: 'var(--secondary-light-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--secondary-default-color)',
    hoverBorderColor: 'var(--secondary-default-color)',
  },
  'secondary-outlined': {
    color: 'var(--secondary-default-color)',
    backgroundColor: 'transparent',
    borderColor: 'var(--secondary-default-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--secondary-default-color)',
    hoverBorderColor: 'var(--secondary-default-color)',
  },
  'secondary-minimal': {
    color: 'var(--primary-default-color)',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    hoverColor: 'var(--primary-default-color)',
    hoverBackgroundColor: 'transparent',
    hoverBorderColor: 'transparent',
  },
  'tertiary-filled': {
    color: '#fff',
    backgroundColor: 'var(--gray-lighter-color)',
    borderColor: 'var(--gray-lighter-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--gray-light-color)',
    hoverBorderColor: 'var(--gray-light-color)',
  },
  'tertiary-outlined': {
    color: 'var(--gray-light-color)',
    backgroundColor: '#fff',
    borderColor: 'var(--gray-light-color)',
    hoverColor: '#fff',
    hoverBackgroundColor: 'var(--gray-light-color)',
    hoverBorderColor: 'var(--gray-light-color)',
  },
  'tertiary-minimal': {
    color: 'var(--gray-light-color)',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    hoverColor: 'var(--black-default-color)',
    hoverBackgroundColor: 'transparent',
    hoverBorderColor: 'transparent',
  },
  'danger-filled': {
    color: '#fff',
    backgroundColor: '#c6c7c5',
    borderColor: '#c6c7c5',
    hoverColor: '#fff',
    hoverBackgroundColor: '#c6c7c7',
    hoverBorderColor: '#c6c7c7',
  },
  'success-filled': {
    color: '#fff',
    backgroundColor: '#52BD95',
    borderColor: '#52BD95',
    hoverColor: '#fff',
    hoverBackgroundColor: '#52BD91',
    hoverBorderColor: '#52BD91',
  },
  'inprogress-filled': {
    color: '#fff',
    backgroundColor: '#FFBF00',
    borderColor: '#FFBF00',
    hoverColor: '#fff',
    hoverBackgroundColor: '#FFBF01',
    hoverBorderColor: '#FFBF01',
  },
  'pink-filled': {
    color: '#fff',
    backgroundColor: '#C2185B',
    borderColor: '#C2185B',
    hoverColor: '#fff',
    hoverBackgroundColor: '#C2185B',
    hoverBorderColor: '#C2185B',
  },
};

const Btn = styled(Button)`
  box-shadow: none !important;
  line-height: 1.2 !important;
  /* font-size: 1rem; */
  border: 1px solid;
  border-color: ${(props) => BTN_LOOK_PROPS[props.look].borderColor};
  color: ${(props) => BTN_LOOK_PROPS[props.look].color};
  background-color: ${(props) => BTN_LOOK_PROPS[props.look].backgroundColor};
  transition: all 500ms ease-out;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transform: skewX(5deg);
    background-color: rgba(255, 255, 255, 0.4);
  }

  &:hover::after {
    transition: all 500ms ease-out;
    width: ${(props) => (props.look.endsWith('filled') ? '100%' : 0)};
    background-color: ${(props) =>
      props.look.endsWith('filled')
        ? 'rgba(255, 255, 255, 0)'
        : 'rgba(255, 255, 255, 0.4)'};
  }

  &:hover {
    border-color: ${(props) =>
      `${BTN_LOOK_PROPS[props.look].hoverBorderColor} !important`};
    color: ${(props) => `${BTN_LOOK_PROPS[props.look].hoverColor} !important`};
    background-color: ${(props) =>
      `${BTN_LOOK_PROPS[props.look].hoverBackgroundColor} !important`};
  }

  &:disabled {
    pointer-events: unset;
    cursor: not-allowed;
    color: ${(props) =>
      props.disableColor || 'rgba(67, 90, 111, 0.7) !important'};

    background-color: rgba(67, 90, 111, 0.06) !important;
    border-color: ${(props) =>
      props.borderColor || 'rgba(67, 90, 111, 0.06) !important'};
    }
  }
`;

Btn.propTypes = {
  look: PropTypes.oneOf([
    'primary-filled',
    'primary-outlined',
    'primary-minimal',
    'secondary-filled',
    'secondary-outlined',
    'secondary-minimal',
    'tertiary-filled',
    'tertiary-outlined',
    'tertiary-minimal',
    'danger-filled',
    'pink-filled',
  ]),
};

Btn.defaultProps = {
  look: 'primary-filled',
};

export default Btn;
