import React, { useState } from 'react';
import {
  Checkbox,
  Heading,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  ArrowRightIcon,
  TickCircleIcon,
  Text,
  Button,
  toaster,
  UnorderedList,
  ListItem,
  Dialog,
} from 'evergreen-ui';
import * as yup from 'yup';
import { useMutation as useReactMutation } from 'react-query';

import styled from '@emotion/styled';
import { Field, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useMutation, gql } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactPixel from 'react-facebook-pixel';
import {
  getErrorMessage,
  optionsToReactSelectFormat,
  textToBoolean,
  getCountryProperty,
  cleanSpaces,
} from '../../helpers/functions';
import { setAuthUser, authUserVar } from '../../helpers/auth';
import loadGoogleRecaptchaAPI from '../../libs/loadGoogleRecaptchaAPI';
import Btn from '../../components/btn/btn';
import { AppLink } from '../../components/footer/footer';
import { VIEWPORT_BREAKPOINTS, NOTIFICATION_TYPE } from '../../helpers/enums';
import Container from '../../components/container/container';
import FormInput from '../../components/form-input/form-input';
import AuthLayout from '../../components/auth-layout/auth-layout';
import AppleAuth from '../../features/auth/apple-auth/apple-auth';
import GoogleAuth from '../../features/auth/google-auth/google-auth';
import FacebookSignup from '../../features/auth/facebook-auth/facebook-auth';
import useSubdomain from '../../hooks/use-subdomain';
import FormSelectBox from '../../components/form-select-box/form-select-box';
import { ReactComponent as AppleStoreIcon } from '../../assets/icons/app-store.svg';
import FormPasswordInput from '../../components/form-password-input/form-password-input';
import TermsAndConditions from '../../components/terms-and-conditions/terms-and-conditions';
import { ReactComponent as GooglePlaystoreIcon } from '../../assets/icons/google-playstore.svg';
import useCountries from '../../hooks/use-countries';
import { tiktokEvent } from '../../libs/tik-tok-pixel';
import { snapchatEvent } from '../../libs/snapchat-pixel';
import apiManager from '../../helpers/apiManager';

const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $countryId: Int
    $confirmPassword: String!
    $isSubscribed: Boolean!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      countryId: $countryId
      confirmPassword: $confirmPassword
      isSubscribed: $isSubscribed
    ) {
      message
    }
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 27px;
  // border-radius: 50%;
  // padding: 25px 18px;
  top: 14px;
  font-size: 1.5em;
  opacity: 0.7;

  border: none;
  background: none;

  &:hover {
    border: none !important;
    background: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

const SIGNUP_FORM_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  countryId: '',
  password: '',
  confirmPassword: '',
  isSubscribed: false,
  acceptTerms: false,
};

const SIGNUP_FORM_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required('First name is required')
    .max(30, 'First name can be at most 30 characters'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required')
    .max(30, 'Last name can be at most 30 characters'),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  // postalCode: yup.string().trim(),
  // contactAddress: yup.string().trim().required('Address is required'),
  countryId: yup.number().required('Country is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .test('confirmPassword', 'Passwords must match', (value, context) => {
      return context.parent.password === value;
    }),
  isSubscribed: yup.boolean().default(false),
  acceptTerms: yup
    .boolean()
    .default(false)
    .required(`You must agree to TicketSir terms and conditions to continue`)
    .test(
      'must agree to terms and conditions',
      `You must agree to TicketSir terms and conditions to continue`,
      (value) => !!value
    ),
});

const StyledCheckbox = styled(Checkbox)`
  & > div {
    border: 2px solid var(--secondary-default-color);
  }

  & > input:focus + div,
  & > input:checked + div {
    box-shadow: none !important;
  }

  & > input:checked + div {
    background-image: none !important;
    background-color: var(--secondary-default-color) !important;
  }

  & > span {
    line-height: 1.2;
    color: var(--text-default-color);
    font-size: 0.875rem;
  }
`;

const StyledFormContainer = styled(Container)`
  /* max-height: 85vh; */
  width: 100%;
  padding-top: 40px;
`;
const SignupDialog = ({
  isSignin,
  setISignin,
  setIsShown,
  isPromoters,
  isFollow,
  CompanyId,
  isFollowHostIncluded,
  refetch,
  isPromotersUser,
}) => {
  // const [mutate] = useMutation(SIGNUP_MUTATION);
  const { mutateAsync: mutate } = useReactMutation(apiManager.UserRegistration);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [Loader, setLoader] = React.useState(false);

  const [reCaptchaToken, setRecaptchaToken] = React.useState('');

  const { hostname } = useSubdomain();
  const recaptchaContainerRef = React.useRef(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = React.useState(false);

  const { mutateAsync: followedHost } = useReactMutation(
    apiManager.FollowTheHostAsync
  );

  const followhost = async (id) => {
    try {
      setLoader(true);

      const { data, message } = await followedHost({
        followedByUserId: id,
        hostId: CompanyId,
        notificationTypeId: NOTIFICATION_TYPE?.EVENTTICKETSALE,
        isFollowing: !isFollowHostIncluded,
      });

      if (data) {
        toaster.success(message || 'Thanks for follow host.');
        setLoader(false);
        refetch();
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  //   React.useEffect(() => {
  //     loadGoogleRecaptchaAPI(() => {
  //       setRecaptchaLoaded(true);
  //     });
  //   }, []);

  //   React.useEffect(() => {
  //     if (recaptchaLoaded) {
  //       if (typeof window.grecaptcha !== 'undefined') {
  //         window.grecaptcha.ready(() => {
  //           window.grecaptcha.render(recaptchaContainerRef.current, {
  //             sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  //             callback: handleRecaptchaVerify,
  //           });
  //         });
  //       }
  //     }
  //   }, [recaptchaLoaded]);

  //   const handleRecaptchaVerify = (response) => {
  //     setRecaptchaToken(response);
  //   };
  const { data: countryList, loading: loadingCountryList } = useCountries();

  React.useEffect(() => {
    if (isSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSuccess]);

  async function handleSignup(values) {
    try {
      setIsLoading(true);
      //   if (!reCaptchaToken) {
      //     toaster.danger('Please complete the ReCAPTCHA verification.');
      //     setIsLoading(false);
      //     return;
      //   }
      // const { data } = await mutate({
      //   variables: {
      //     ...values,
      //   },
      // });

      const { data } = await mutate({
        ...values,
        registerType: 'email',
        isapplogin: false,
      });

      if (data) {
        const { firstName, lastName, email, countryId } = values;
        const country = getCountryProperty(
          countryList?.CountryDetails?.CountryDetails,
          countryId,
          'CountryDescription'
        );
        tiktokEvent('CompleteRegistration', {
          firstName,
          lastName,
          email,
          country,
        });
        ReactPixel.fbq('track', 'CompleteRegistration', {
          firstName,
          lastName,
          email,
          country,
        });
        snapchatEvent('SIGN_UP', { firstName, lastName, email, country });
        setIsSuccess(true);
      }
    } catch (error) {
      setIsSuccess(false);
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  }

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });
  return (
    <Pane>
      <Dialog
        isShown={isSignin}
        onCloseComplete={() => setISignin(false)}
        confirmLabel="Custom Label"
        hasFooter={false}
        contentContainerProps={{
          paddingX: 0,
        }}
        hasHeader={false}
        width="784px"
        shouldCloseOnOverlayClick={false}
        preventBodyScrolling={true}
      >
        <AuthLayout
          type={isSuccess ? 'register_success' : 'signup'}
          isPromoter={true}
          isSuccess={isSuccess}
        >
          <StyledFormContainer>
            {isSuccess ? (
              <RegisterSuccess
                setISignin={setISignin}
                setIsSuccess={setIsSuccess}
              />
            ) : (
              <>
                <Heading
                  fontSize="2rem"
                  fontStyle="normal"
                  fontWeight="900"
                  lineHeight={1.2}
                  letterSpacing="0em"
                >
                  Sign up
                </Heading>
                {!hostname && (
                  <Pane
                    marginY={majorScale(2)}
                    className="row w-100"
                    justifyContent="space-between"
                  >
                    <Pane className="col-12 col-md-6">
                      <GoogleAuth
                        isFollow={isFollow}
                        isPromoters={isPromoters}
                        followhost={followhost}
                        isPromotersUser={isPromotersUser}
                        setIsShown={setIsShown}

                        // callback={() => null}
                      />
                    </Pane>
                    {/* hide facebook */}
                    <Pane className="col-12 col-md-4" display="none">
                      <FacebookSignup
                        isFollow={isFollow}
                        isPromoters={isPromoters}
                        followhost={followhost}
                        isPromotersUser={isPromotersUser}
                        setIsShown={setIsShown}
                      />
                    </Pane>
                    <Pane className="col-12 col-md-6">
                      <AppleAuth
                        isFollow={isFollow}
                        isPromoters={isPromoters}
                        followhost={followhost}
                        isPromotersUser={isPromotersUser}
                        setIsShown={setIsShown}
                        isFollowHostIncluded={isFollowHostIncluded}
                        CompanyId={CompanyId}
                      />
                    </Pane>
                  </Pane>
                )}

                <Formik
                  validationSchema={SIGNUP_FORM_SCHEMA}
                  initialValues={SIGNUP_FORM_INITIAL_VALUES}
                  onSubmit={(values) => handleSignup(cleanSpaces(values))}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <FormInput
                          marginBottom={majorScale(2)}
                          inputHeight={majorScale(5)}
                          label="First Name *"
                          name="firstName"
                          placeholder="Enter first name"
                          value={values.firstName}
                          isInvalid={touched.firstName && !!errors.firstName}
                          validationMessage={
                            touched.firstName && errors.firstName
                          }
                          onChange={handleChange}
                        />

                        <FormInput
                          marginBottom={majorScale(2)}
                          inputHeight={majorScale(5)}
                          label="Last Name *"
                          name="lastName"
                          placeholder="Enter last name"
                          value={values.lastName}
                          isInvalid={touched.lastName && !!errors.lastName}
                          validationMessage={
                            touched.lastName && errors.lastName
                          }
                          onChange={handleChange}
                        />

                        <FormInput
                          marginBottom={majorScale(2)}
                          inputHeight={majorScale(5)}
                          label="Email *"
                          inputMode="email"
                          name="email"
                          placeholder="Enter email"
                          value={values.email}
                          isInvalid={touched.email && !!errors.email}
                          validationMessage={touched.email && errors.email}
                          onChange={handleChange}
                        />

                        <Field name="countryId">
                          {({ field, meta, form }) => {
                            return (
                              <Pane marginBottom={majorScale(2)}>
                                <FormSelectBox
                                  height={majorScale(5)}
                                  label="Country *"
                                  placeholder="Select country"
                                  isLoading={loadingCountryList}
                                  options={optionsToReactSelectFormat(
                                    countryList?.CountryDetails?.CountryDetails,
                                    {
                                      label: 'CountryDescription',
                                      value: 'CountryId',
                                    }
                                  )}
                                  name={field.name}
                                  value={field.value}
                                  isInvalid={meta.touched && !!meta.error}
                                  validationMessage={meta.touched && meta.error}
                                  onChange={(selected) => {
                                    form.setFieldValue(
                                      field.name,
                                      selected.value
                                    );
                                  }}
                                />
                              </Pane>
                            );
                          }}
                        </Field>

                        <FormPasswordInput
                          marginBottom={majorScale(2)}
                          inputHeight={majorScale(5)}
                          label="Password *"
                          name="password"
                          placeholder="Enter password"
                          value={values.password}
                          isInvalid={touched.password && !!errors.password}
                          validationMessage={
                            touched.password && errors.password
                          }
                          onChange={handleChange}
                        />

                        <FormPasswordInput
                          marginBottom={majorScale(2)}
                          inputHeight={majorScale(5)}
                          label="Confirm Password *"
                          name="confirmPassword"
                          placeholder="Enter password again"
                          value={values.confirmPassword}
                          isInvalid={
                            touched.confirmPassword && !!errors.confirmPassword
                          }
                          validationMessage={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          onChange={handleChange}
                        />

                        <Pane
                          className="row"
                          marginRight="0 !important"
                          marginLeft="0 !important"
                        >
                          <UnorderedList className="col-md-6">
                            <ListItem
                              icon={<TickCircleIcon />}
                              iconColor={
                                values.password && /[A-Z]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              color={
                                values.password && /[A-Z]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              fontSize="1em"
                            >
                              Uppercase Character
                            </ListItem>

                            <ListItem
                              icon={<TickCircleIcon />}
                              iconColor={
                                values.password && /[a-z]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              color={
                                values.password && /[a-z]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              fontSize="1em"
                            >
                              Lowercase Character
                            </ListItem>

                            <ListItem
                              icon={<TickCircleIcon />}
                              iconColor={
                                values.password && /[0-9]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              color={
                                values.password && /[0-9]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              fontSize="1em"
                              marginBottom="0"
                            >
                              One Number
                            </ListItem>
                          </UnorderedList>
                          <UnorderedList className="col-md-6">
                            <ListItem
                              icon={<TickCircleIcon />}
                              iconColor={
                                values.password &&
                                /[^a-zA-Z0-9]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              color={
                                values.password &&
                                /[^a-zA-Z0-9]/.test(values.password)
                                  ? 'success'
                                  : 'muted'
                              }
                              fontSize="1em"
                            >
                              One Special Character
                            </ListItem>

                            <ListItem
                              icon={<TickCircleIcon />}
                              iconColor={
                                values.password && values.password.length >= 8
                                  ? 'success'
                                  : 'muted'
                              }
                              color={
                                values.password && values.password.length >= 8
                                  ? 'success'
                                  : 'muted'
                              }
                              fontSize="1em"
                            >
                              8 Characters Minimum
                            </ListItem>
                          </UnorderedList>
                        </Pane>

                        <Field name="isSubscribed">
                          {({ field, form, meta }) => {
                            return (
                              <StyledCheckbox
                                marginBottom={majorScale(2)}
                                fontSize="0.9rem"
                                name={field.name}
                                checked={field.value}
                                label={`I’d like to receive emails from TicketSir about upcoming events in my city, educational resources and updates.`}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        </Field>

                        <Field name="acceptTerms">
                          {({ field, form, meta }) => {
                            return (
                              <>
                                <StyledCheckbox
                                  marginBottom={majorScale(2)}
                                  fontSize="0.9rem"
                                  name={field.name}
                                  checked={field.value}
                                  label={
                                    <Text>
                                      I Agree to TicketSir{' '}
                                      <Text
                                        color="var(--primary-default-color)"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsModalOpen(true);
                                        }}
                                      >
                                        Terms and Conditions
                                      </Text>
                                    </Text>
                                  }
                                  onChange={field.onChange}
                                />
                                <Pane
                                  marginBottom={majorScale(3)}
                                  display={
                                    meta.touched && meta.error ? 'flex' : 'none'
                                  }
                                  columnGap={minorScale(2)}
                                  color="#D14343"
                                >
                                  <Paragraph
                                    letterSpacing={0}
                                    color="#D14343"
                                    lineHeight="18px"
                                    fontSize="13px"
                                    fontWeight={400}
                                    marginBottom={0}
                                  >
                                    {meta.touched && meta.error}
                                  </Paragraph>
                                </Pane>
                              </>
                            );
                          }}
                        </Field>

                        {isModalOpen && (
                          <TermsAndConditions
                            isOpen={isModalOpen}
                            close={() => setIsModalOpen(false)}
                          />
                        )}

                        {/* <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(value) => setRecaptchaToken(value)}
                        render="explicit"
                      /> */}

                        <div ref={recaptchaContainerRef}></div>

                        <Container className="px-0">
                          <Btn
                            type="submit"
                            width="100%"
                            marginY={majorScale(3)}
                            fontSize="1em"
                            isLoading={isLoading}
                          >
                            Create Account
                          </Btn>
                        </Container>
                      </form>
                    );
                  }}
                </Formik>
                {/* <Pane textAlign="center" marginBottom={majorScale(2)}>
                  <Text>
                    Already have an account?{' '}
                    <Text fontWeight="bold">
                      <Link to="/signin">Sign In</Link>
                    </Text>
                  </Text>
                </Pane> */}
              </>
            )}
          </StyledFormContainer>

          {/* {!isLaptop && !isSuccess && <SigninOption />} */}
        </AuthLayout>
        <CloseButton
          onClick={() => {
            setISignin(false);
            setIsSuccess(false);
          }}
        >
          X
        </CloseButton>
      </Dialog>
    </Pane>
  );
};

const RegisterSuccess = ({ setISignin, setIsSuccess }) => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginY={majorScale(10)}
      height="180px"
    >
      <Pane marginY={majorScale(2)} textAlign="center">
        <TickCircleIcon size={60} color="var(--primary-default-color)" />
      </Pane>
      <Heading
        fontSize="1.5rem"
        marginBottom={minorScale(4)}
        color="var(--primary-default-color)"
      >
        Registration Successful!
      </Heading>

      <Paragraph fontSize="1rem">
        Please check your email and follow the instruction to verify your
        account.
      </Paragraph>
      <Container className="px-0" display="flex" justifyContent="center">
        <Btn
          marginY={majorScale(3)}
          fontSize="1em"
          width="100px"
          onClick={() => {
            setISignin(false);
            setIsSuccess(false);
          }}
        >
          ok
        </Btn>
      </Container>
    </Pane>
  );
};
const SigninOption = () => {
  return (
    <Pane
      className="col-12 d-md-none"
      textAlign="center"
      paddingY={majorScale(7)}
      background="#F6F6F6"
    >
      <Paragraph
        marginBottom={minorScale(5)}
        color="var(--text-default-color)"
        fontSize="1rem"
      >
        Already have an account?
      </Paragraph>
      <Link to="/signin">
        <Btn
          fontSize="1em"
          type="button"
          look="secondary-filled"
          columnGap={minorScale(3)}
          marginX="auto"
        >
          Sign in
          <ArrowRightIcon />
        </Btn>
      </Link>
      <Pane
        className="download-options"
        display="flex"
        justifyContent="space-between"
        paddingY={majorScale(1)}
        marginTop={majorScale(3)}
        paddingBottom={majorScale(2)}
        gap={'1.5rem'}
      >
        {textToBoolean(process.env.REACT_APP_SHOW_APP_DOWNLOAD_LINKS) && (
          <>
            {/* <AppLink
              href={process.env.REACT_APP_APP_STORE_LINK}
              target="_blank"
              rel="noreferrer"
              color="neutral"
            >
              <AppleStoreIcon cursor="pointer" />
            </AppLink>
            <AppLink
              href={process.env.REACT_APP_GOOGLE_PLAY_LINK}
              target="_blank"
              rel="noreferrer"
              color="neutral"
            >
              <GooglePlaystoreIcon cursor="pointer" />
            </AppLink> */}
          </>
        )}
      </Pane>
    </Pane>
  );
};

export default SignupDialog;
