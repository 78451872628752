import React from 'react';
import {
  Pane,
  Heading,
  majorScale,
  toaster,
  Paragraph,
  Text,
  minorScale,
} from 'evergreen-ui';
import Countdown, { zeroPad } from 'react-countdown';
import { useReactiveVar } from '@apollo/client';
import { useMutation } from 'react-query';
import { authUserVar } from '../../helpers/auth';
import { HTTP_REQ_STATUS } from '../../helpers/enums';
import apiManager from '../../helpers/apiManager';
import Btn from '../btn/btn';

const ActivateYourAccountMessage = ({ message, buttonText }) => {
  const authUser = useReactiveVar(authUserVar);
  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [countdownKey, setCountdownKey] = React.useState(0);

  const { mutateAsync: mutate } = useMutation(
    apiManager.InitiateEmailVerification
  );

  async function handleResendActivationLink() {
    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data, message } = await mutate({
        email: authUser.email,
        IsResendActivation: true,
      });

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);
        setIsDisabled(true);
        setCountdownKey((prevKey) => prevKey + 1); // Reset the countdown
        toaster.success(message, {
          id: 'email-success',
          description: 'Please check your email inbox for activation link.',
        });
      }
    } catch (error) {
      toaster.danger(error);
      setStatus(HTTP_REQ_STATUS.error);
      setIsDisabled(false);
    }
  }

  const CountdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsDisabled(false);
      return null;
    } else {
      return (
        <Pane>
          <Paragraph fontSize="1em">
            {' '}
            Resend activation mail in{' '}
            <Text fontSize="1em" fontWeight="700">
              {zeroPad(minutes)} minute
            </Text>{' '}
            <Text fontSize="1em" fontWeight="700">
              :
            </Text>
            <Text fontSize="1em" fontWeight="700" marginLeft={minorScale(1)}>
              {zeroPad(seconds)} second
            </Text>
          </Paragraph>
        </Pane>
      );
    }
  };

  return (
    <>
      <Heading fontSize="1em" textAlign="center" marginBottom={majorScale(2)}>
        {message
          ? message
          : 'You are almost in!!! To view your user area, please activate your account by clicking the activation link that we sent to your email.'}
      </Heading>
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={isDisabled && majorScale(2)}
      >
        <Btn
          type="button"
          fontSize="1em"
          isLoading={status === HTTP_REQ_STATUS.loading}
          onClick={() => handleResendActivationLink()}
          disabled={isDisabled}
        >
          {buttonText ? buttonText : 'Resend Activation Email'}
        </Btn>
      </Pane>

      {isDisabled && (
        <Pane textAlign="center">
          <Countdown
            key={countdownKey}
            date={Date.now() + 120000} // 2 minutes in milliseconds
            renderer={CountdownRenderer}
          />
        </Pane>
      )}
    </>
  );
};

export default ActivateYourAccountMessage;
