import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  Pane,
  ListItem,
  UnorderedList,
  majorScale,
  Paragraph,
} from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import AuthHeader from '../../auth-header/auth-header';
import ticketSirLogo from '../../../assets/images/ticketsir-logo.png';
import Skeleton from 'react-loading-skeleton';
import { WithSubdomainLink } from '../../../hooks/use-subdomain';
import Notification from '../../notification-bell/notification-bell';
import useSubdomain from '../../../hooks/use-subdomain';
import { authUserVar } from '../../../helpers/auth';
import Btn from '../../btn/btn';

export const PRIMARY_NAV_ITEMS = [
  {
    exact: true,
    shown: true,
    title: 'Home',
    path: '/',
  },
  {
    exact: true,
    shown: true,
    title: 'Events',
    path: '/search-events',
  },
  {
    exact: false,
    shown: true,
    title: 'Create Event',
    path: '/host/create-event',
    domainOnly: true,
    unauthenticatedRedirect: '/how-to-create-event',
    isColor: true,
  },
];

const navStyle = {
  style: {
    display: 'inline-block',
    padding: '8px 0',
    color: '#fff',
    marginRight: '40px',
  },
  activeStyle: {
    borderBottom: '1px solid #fff',
  },
};

const DesktopHeader = ({ subdomainLogoUrl, loadingLogo }) => {
  const { subdomain } = useSubdomain();
  const authUser = useReactiveVar(authUserVar);

  const isPathDisabled = (paths) => paths.includes(window.location.pathname);

  return (
    <Pane
      is="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontSize="0.9rem"
    >
      <NavLink to="/">
        {loadingLogo ? (
          <Paragraph>
            <Skeleton width={100} height={20} />
          </Paragraph>
        ) : (
          <img
            src={subdomainLogoUrl || ticketSirLogo}
            alt="Ticketsir logo"
            style={{ verticalAlign: 'middle', maxHeight: '60px' }}
          />
        )}
      </NavLink>

      <Pane
        is="nav"
        marginLeft={majorScale(5)}
        display="flex"
        alignItems="center"
        fontSize="1em"
      >
        <UnorderedList display="flex" alignItems="center">
          {PRIMARY_NAV_ITEMS.filter(({ shown }) => shown).map(
            (
              {
                exact,
                unauthenticatedRedirect,
                domainOnly,
                path,
                title,
                isColor,
              },
              i
            ) => {
              return (
                <ListItem key={i} fontSize="inherit">
                  {authUser?.id &&
                  !authUser?.hostId &&
                  unauthenticatedRedirect &&
                  domainOnly &&
                  isColor ? (
                    <Link to={unauthenticatedRedirect}>
                      <Btn
                        fontSize="16px"
                        look="pink-filled"
                        marginRight={40}
                        borderColor={'#C4C4C4'}
                        disableColor={'#C4C4C4'}
                        disabled={isPathDisabled([
                          '/how-to-create-event',
                          '/how-to-create-event-sign-in',
                          '/register-successfully',
                        ])}
                      >
                        {title}
                      </Btn>
                    </Link>
                  ) : authUser?.id && authUser?.hostId && isColor ? (
                    <Link to={path}>
                      <Btn
                        look="pink-filled"
                        fontSize="16px"
                        marginRight={40}
                        borderColor={'#C4C4C4'}
                        disableColor={'#C4C4C4'}
                        disabled={isPathDisabled([
                          '/host/create-event',
                          '/how-to-create-event-sign-in',
                          '/register-successfully',
                        ])}
                      >
                        {title}
                      </Btn>
                    </Link>
                  ) : !domainOnly ? (
                    <NavLink
                      exact={exact}
                      to={path}
                      activeStyle={navStyle.activeStyle}
                      style={navStyle.style}
                    >
                      {title}
                    </NavLink>
                  ) : null}
                </ListItem>
              );
            }
          )}
        </UnorderedList>
        {!subdomain?.length && <Notification />}
        <AuthHeader />
        {!authUser?.id && (
          <Link to={'/how-to-create-event'}>
            <Btn
              look="pink-filled"
              fontSize="16px"
              borderColor={'#C4C4C4'}
              disableColor={'#C4C4C4'}
              disabled={isPathDisabled([
                '/how-to-create-event',
                '/how-to-create-event-sign-in',
                '/register-successfully',
              ])}
            >
              Create event
            </Btn>
          </Link>
        )}
      </Pane>
    </Pane>
  );
};

export default DesktopHeader;
