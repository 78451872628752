import React from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import get from 'lodash/get';

import MobileHeader from './mobile-header/mobile-header';
import { VIEWPORT_BREAKPOINTS } from '../../helpers/enums';
import DesktopHeader from './desktop-header/desktop-header';
import { useLocation } from 'react-router-dom';
import { useSubdomainLogo } from '../../hooks/use-subdomain';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
ReactPixel.init(process.env.REACT_APP_COKOBAR_FACEBOOK_PIXEL_ID);

const Header = () => {
  const location = useLocation();

  const { loading, data } = useSubdomainLogo();
  const subdomainLogoUrl = get(
    data,
    'allCompanies.items[0].logoImageUrl',
    undefined
  );

  React.useEffect(() => {
    ReactPixel.pageView();
  }, [location.pathname]);

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ADS_CONVERSION}`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GOOGLE_ADS_CONVERSION}');
          `}
        </script>
        <script type="text/javascript">
          {`
      (function (e, t, n) {
        if (e.snaptr) return;
        var a = (e.snaptr = function () {
          a.handleRequest
            ? a.handleRequest.apply(a, arguments)
            : a.queue.push(arguments);
        });
        a.queue = [];
        var s = "script";
        var r = t.createElement(s);
        r.async = !0;
        r.src = n;
        var u = t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r, u);
      })(window, document, "https://sc-static.net/scevent.min.js");
      
      snaptr("init", "${process.env.REACT_APP_SNAPCHAT_PIXEL_ID}", {
        user_email: "__INSERT_USER_EMAIL__",
      });
      
      snaptr("track", "PAGE_VIEW");
      `}
        </script>
        <script type="text/javascript">
          {`
!function (w, d, t) {
w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

ttq.load('${process.env.REACT_APP_TIK_TOK_PIXEL_ID}');
ttq.page();
}(window, document, 'ttq');
      
      `}
        </script>
      </Helmet>
      {!isLaptop ? (
        <MobileHeader
          loadingLogo={loading}
          subdomainLogoUrl={subdomainLogoUrl}
        />
      ) : (
        <DesktopHeader
          loadingLogo={loading}
          subdomainLogoUrl={subdomainLogoUrl}
        />
      )}
    </>
  );
};

export default Header;
