import * as React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import {
  Pane,
  Heading,
  Paragraph,
  minorScale,
  majorScale,
  TickCircleIcon,
} from 'evergreen-ui';

import { VIEWPORT_BREAKPOINTS } from '../../../helpers/enums';
import VerifyToken from './forgot-password-steps/verify-token';
import Container from '../../../components/container/container';
import RequestToken from './forgot-password-steps/request-token';
import ResetPassword from './forgot-password-steps/reset-password';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import ticketSirLogo from '../../../assets/images/ticketsir-logo-lg.png';

const StyledModal = styled(Pane)`
  margin-right: -30px !important;
  margin-left: -30px !important;
`;

const StyledHeading = styled(Heading)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    font-size: 1.7rem;
  }
`;

const StyledFormContainer = styled(Container)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    padding: 120px 0;
  }
`;

function ForgotPassword({ closeModal, setCurrentAuthView }) {
  const MIN_STEP = 0;
  const MAX_STEP = 2;

  const [currentStep, setCurrentStep] = React.useState(MIN_STEP);
  const [email, setEmail] = React.useState('');

  const increaseStep = React.useCallback(() => {
    setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
  }, [MAX_STEP]);

  function RequestTokenSuccess({ increaseStep }) {
    return (
      <>
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginY={majorScale(10)}
        >
          <Pane marginY={majorScale(2)} textAlign="center">
            <TickCircleIcon size={60} color="var(--primary-default-color)" />
          </Pane>
          <Heading
            fontSize="1.5rem"
            marginBottom={minorScale(4)}
            color="var(--primary-default-color)"
            textAlign="center"
          >
            Kindly Check your email!
          </Heading>
          <Paragraph fontSize="1rem" textAlign="center">
            An activation email has been sent to your email.
          </Paragraph>
        </Pane>
      </>
    );
  }

  function renderBasedOnCurrentView() {
    switch (currentStep) {
      case 0:
        return (
          <RequestToken
            setCurrentAuthView={setCurrentAuthView}
            increaseStep={increaseStep}
          />
        );

      case 1:
        return <RequestTokenSuccess />;

      default:
        return null;
    }
  }

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <StyledModal className="row" position="relative">
      <Pane
        className="col-md-5"
        backgroundColor={!isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
      >
        <Container paddingTop={majorScale(4)}>
          <Pane
            width={isLaptop ? 180 : 130}
            overflow="hidden"
            marginBottom={majorScale(5)}
          >
            <img src={ticketSirLogo} alt="Logo" style={{ maxWidth: '100%' }} />
          </Pane>
        </Container>

        <Container maxWidth={420}>
          <StyledHeading
            marginBottom={minorScale(5)}
            fontWeight={900}
            fontSize="2rem"
          >
            Reset your password
          </StyledHeading>

          <Paragraph
            marginBottom={minorScale(5)}
            color="var(--text-default-color)"
            fontSize="1rem"
          >
            Please provide the email you used during your registration on
            TicketSir.
          </Paragraph>
        </Container>
      </Pane>

      <Pane
        className="col"
        backgroundColor={isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
        paddingY={majorScale(6)}
      >
        <StyledFormContainer maxWidth={420} paddingY={majorScale(7)}>
          {renderBasedOnCurrentView()}
        </StyledFormContainer>
      </Pane>

      <CloseIcon
        onClick={() => {
          closeModal();
        }}
        style={{
          position: 'absolute',
          right: '40px',
          top: '8%',
          cursor: 'pointer',
        }}
      />
    </StyledModal>
  );
}

export default ForgotPassword;
