const loadGoogleAPI = (callback) => {
  const scriptName = 'google-api';

  const existingScript = document.getElementById(scriptName);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.type = 'text/javascript';
    script.id = script;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadGoogleAPI;
