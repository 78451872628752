import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';

import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  split,
} from '@apollo/client';
import {
  getMainDefinition,
  offsetLimitPagination,
} from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { handleLogout } from '../helpers/auth';
import { toaster } from 'evergreen-ui';
import {
  EXPIRED_JWT_ERROR_MESSAGE,
  TS_AUTH_USER_REST_TOKEN,
} from '../constants';
import clientStorage from './client-storage';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const retryLink = new RetryLink();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_V2,
});

export const wsClient = new SubscriptionClient(
  process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
  {
    reconnect: true,
    connectionParams: {},
  }
);

const wsLink = new WebSocketLink(wsClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  const restApiToken = clientStorage.getItem(TS_AUTH_USER_REST_TOKEN);

  return {
    headers: {
      ...headers,
      ...(restApiToken
        ? { authorization: `Bearer ${restApiToken}` }
        : {
            'x-Api-Key':
              'cokobarw#D587M@T63fW/XNBP4QR3D6YyMWXGiJr4J2ooEcKtyDxqc7xbr6+fkj3VBpch/HPCLjIyCN',
          }),
    },
  };
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const { path, locations, message } = graphQLError;

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (message === EXPIRED_JWT_ERROR_MESSAGE) {
        handleLogout();
        toaster.notify('Your session has expired. Please login again.', {
          id: 'session_expired',
        });
      }
    });
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, authLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          search: offsetLimitPagination([
            'category',
            'date',
            'location_info',
            'price',
            'search_term',
            'ticket_type',
          ]),
        },
      },
    },
  }),
});

function ApolloClientProvider(props) {
  return <ApolloProvider client={apolloClient} {...props} />;
}

export { ApolloClientProvider as default, apolloClient };
