import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  Button,
  minorScale,
  toaster,
} from 'evergreen-ui';
import { Formik } from 'formik';
import { useMutation as ReactQueryMutation } from 'react-query';

import { useReactiveVar } from '@apollo/client';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Btn from '../../btn/btn';
import Container from '../../container/container';
import FormInput from '../../form-input/form-input';
import { authUserVar, tokenVar } from '../../../helpers/auth';
import useResponsiveness from '../../../hooks/use-responsiveness';
import { HTTP_REQ_STATUS, VIEWPORT_BREAKPOINTS } from '../../../helpers/enums';
import apiManager from '../../../helpers/apiManager';
const StyledFormContainer = styled(Container)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    padding: 120px 0;
  }
`;

const FORM_SCHEMA_ACTIVATION_EMAIL = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
});

const CloseButton = styled(Button)`
  position: absolute;
  right: 20px;
  border-radius: 50%;
  padding: 25px 18px;
  top: 15px;
  font-size: 1.5em;
  opacity: 0.7;
  border: none;

  &:hover {
    border: none !important;
  }
`;

const ResendActivationEmail = ({ setActive, setISignin }) => {
  const { isLaptop, isSmallScreen } = useResponsiveness();

  const authUser = useReactiveVar(authUserVar);
  const history = useHistory();
  const token = tokenVar();

  const [loader, setLoader] = React.useState();

  const { mutateAsync: mutate } = ReactQueryMutation(
    apiManager.InitiateEmailVerification
  );
  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);

  async function handleResendActivationLink(values) {
    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data, message } = await mutate({
        ...values,
        IsResendActivation: true,
      });

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);

        toaster.success(message, {
          id: 'email-success',
          description: 'Please check your email inbox for activation link.',
        });

        setActive(false);
        setISignin(true);
      }
    } catch (error) {
      toaster.danger(error);
      setStatus(HTTP_REQ_STATUS.error);
    }
  }

  return (
    <Formik
      validationSchema={FORM_SCHEMA_ACTIVATION_EMAIL}
      initialValues={{ email: '' }}
      onSubmit={handleResendActivationLink}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => {
        return (
          <>
            <>
              <Paragraph marginBottom={majorScale(2)} marginTop={majorScale(1)}>
                Please provide the email you used during your registration on
                TicketSir.
              </Paragraph>
              <form onSubmit={handleSubmit}>
                <FormInput
                  marginBottom={majorScale(2)}
                  inputHeight={majorScale(5)}
                  label="Email"
                  inputMode="email"
                  name="email"
                  placeholder="Enter email"
                  value={values.email}
                  isInvalid={touched.email && !!errors.email}
                  validationMessage={touched.email && errors.email}
                  onChange={handleChange}
                />

                <Container maxWidth={isLaptop ? 300 : 'unset'}>
                  <Btn
                    type="submit"
                    marginY={majorScale(3)}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="1em"
                    isLoading={status === HTTP_REQ_STATUS.loading}
                  >
                    Request Activation Link
                  </Btn>
                </Container>
              </form>
            </>
          </>
        );
      }}
    </Formik>
  );
};

const ResendActivationLink = ({ isActive, setActive, setISignin }) => {
  const MIN_STEP = 0;
  const MAX_STEP = 1;

  const [currentStep, setCurrentStep] = React.useState(MIN_STEP);

  function renderBasedOnCurrentView() {
    switch (currentStep) {
      case 0:
        return (
          <ResendActivationEmail
            setActive={setActive}
            setISignin={setISignin}
          />
        );

      default:
        return null;
    }
  }

  return (
    <Dialog
      isShown={isActive}
      onCloseComplete={() => setActive(false)}
      hasFooter={false}
      //   contentContainerProps={{ padding: 0 }}
      hasHeader={false}
      width="784px"
      shouldCloseOnOverlayClick={false}
      preventBodyScrolling={true}
    >
      <Pane
        maxHeight="27vh"
        borderTopLeftRadius="5px"
        width="100%"
        borderTopRightRadius="5px"
        alt=""
        maxWidth="100%"
        position="relative"
      />
      <CloseButton onClick={() => setActive(false)}>X</CloseButton>
      <>
        <StyledFormContainer maxWidth={420} paddingY={minorScale(15)}>
          <Heading
            fontSize="2rem"
            fontWeight="900"
            lineHeight="34px"
            letterSpacing="0em"
            textAlign="left"
          >
            Resend Activation Email
          </Heading>

          {renderBasedOnCurrentView()}
        </StyledFormContainer>
      </>
    </Dialog>
  );
};

export default ResendActivationLink;
