export function getErrorMessage(error) {
    if (error.response?.data?.message || error.response?.data?.error) {
        return error.response?.data?.message || error.response?.data?.error;
    }

    if (error?.message) {
        return error?.message;
    }

    if (error?.request) {
        return 'Something went wrong';
    }
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.substring(23));
        reader.onerror = (error) => reject(error);
    });
};
