import * as React from 'react';
import { Paragraph } from 'evergreen-ui';

function ErrorStateMsg({ msg }) {
  return (
    <Paragraph
      width="80%"
      maxWidth={360}
      marginX="auto"
      paddingY={30}
      textAlign="center"
      fontSize="1.225em"
      fontStyle="italic"
      color="#A73636"
    >
      {msg}
    </Paragraph>
  );
}

export default ErrorStateMsg;
