import { useMediaQuery } from 'react-responsive';
import { VIEWPORT_BREAKPOINTS } from '../helpers/enums';

const useResponsiveness = (_) => {
  const isSmallScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const isLaptopLg = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.xxl}px)`,
  });

  const isLaptopMd = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.lg}px)`,
  });

  return {
    isSmallScreen,
    isLaptop,
    isLaptopMd,
    isLaptopLg,
  };
};

export default useResponsiveness;
