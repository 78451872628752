import { gql, useQuery } from '@apollo/client';
import { toaster } from 'evergreen-ui';
import { getErrorMessage } from '../helpers/functions';

export const defaultCountry = {
  __typename: 'CountryDetails',
  CountryId: 9,
  CountryDescription: 'United Kingdom',
  CountryInternationalDDCode: null,
  CurrencyId: null,
  DateCreated: '2012-09-13T15:04:41.327',
  ISO2: 'GB',
  ISO3: 'GBR',
  InterCurrencyId: null,
  IsActive: true,
  IsEnableFundWithdrawal: true,
  IsInternationalCurrency: null,
  TicketAdminBase: 0,
  TicketAdminPercentage: 12.5,
};

const COUNTRIES_QUERY = gql`
  query CountriesQueryHomePage {
    CountryDetails: countriesForHomepage {
      CountryDetails: items {
        CountryDescription: countryDescription
        CountryId: countryId
        CountryInternationalDDCode: countryInternationalDdcode
        CurrencyId: currencyId
        DateCreated: dateCreated
        ISO2: iso2
        ISO3: iso3
        InterCurrencyId: interCurrencyId
        IsActive: isActive
        IsEnableFundWithdrawal: isEnableFundWithdrawal
        IsInternationalCurrency: isInternationalCurrency
        TicketAdminBase: ticketAdminBase
        TicketAdminPercentage: ticketAdminPercentage
      }
      totalCount
    }
  }
`;

function useCountriesHomePage(config = {}) {
  return useQuery(COUNTRIES_QUERY, {
    ...config,
    onError: (error) => {
      toaster.danger(getErrorMessage(error), {
        id: 'fetch-countries-error',
        description: 'An error occurred while fetching countries.',
      });
    },
  });
}

export function useSingleCountry() {
  const { data: countries } = useCountriesHomePage();

  return (key, value, responseKey) =>
    countries?.CountryDetails?.CountryDetails?.filter(
      (country) => country[key] === value
    )?.[0]?.[responseKey];
}

export default useCountriesHomePage;
